import React, { useEffect, useState } from 'react';
import { withRouter, Link, useParams, useHistory } from 'react-router-dom';
import { compose } from 'recompose';

import { API } from 'aws-amplify'

import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Modal, Row, Col, Spinner } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet';



const SignUpAccessCodePage = () => (
  <Container style={{padding:0}}>
      <Helmet>
        <title>Access Code Registration | PWT</title>
        <meta name="description" content="Sign up with your special access code."/>
        <meta name="robots" content="noindex"/>
      </Helmet> 
      <SignUpAccessCodeBase></SignUpAccessCodeBase>
  </Container>            
);



function SignUpAccessCodeBase(props) {

  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Start Your PWT Membership</h1>
      <h2>Complete Your Registration</h2>
      <SignUpAccessCodeForm></SignUpAccessCodeForm>
      <div style={{paddingTop:10}}>
        <Link to={ROUTES.SIGN_IN}>Already have an account? Sign in.</Link>
      </div>
    </div>  
  );
}



function SignUpAccessCodeModule(props) {
    function QueryURL() {
      let { code } = useParams();
      if (code == null) {
        code = ''
      } 
      return { code }
    }

    let query = QueryURL();
    
    const [showError, setShowError] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [group, setGroup] = useState(null);

    const [onboardingMessage, setOnboardingMessage] = useState(null);

    const [username, setUsername] = useState("");
    
    const [email, setEmail] = useState("");

    const [showCompleteModal, setShowCompleteModal] = useState(false);

    const [lockAccessCode, setLockAccessCode] = useState(false);

    const history = useHistory();

    const handleClose = () => setShowCompleteModal(false);
    const handleShow = () => setShowCompleteModal(true);

    const validationSchema = Yup.object().shape({
      username: Yup.string()
      .min(2, "Username must have at least 2 characters.")
      .max(64, "Username can't be longer than 64 characters.")
      .required("Username is required.")
      .matches(
        /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/,
        "Username may contain only letters and numbers, optionally separated by underscores (_) or dashes (-)."
      ),
      email: Yup.string()
      .email("Must be a valid email address.")
      .required("Email is required."),
      emailConfirm: Yup.string()
        .required("Please confirm your email address.")
        .label('Confirm email')
        .test('emails-match', 'Emails must match.', function(value) {
          return this.parent.email === value;
      }),
      firstName: Yup.string()
        .required("Your first name is required.")
        // .max(1, "Your initial must be at most 1 character long."),
        .max(50, "Your first name must be at most 50 characters long."),
        lastName: Yup.string()
        .required("Your last name is required.")
        .max(50, "Your last name must be at most 50 characters long."),
      code: Yup.string()
      .required("Access Code is required")
      .label('Access Code'),
      acceptTerms: Yup.bool()
      .oneOf([true], 'Please read and accept the ToS.'),
    });


    function CompleteSignIn() {
      setShowCompleteModal(false);
      history.push("/");
    }


    useEffect(() => {
      async function setupGroup() {
        if (query != null) {
          if (query.code == "") {
            // props.history.push('/group/redeem');
          } else {
            setLockAccessCode(true);
          }
        }
      }
      
      setupGroup();
    }, [query.code]); 

    return(
      <div>       
        <Formik
          initialValues={{ username: '', email: '', emailConfirm: '', firstName: "", lastName: "", code:query.code, acceptTerms:false}}
          validationSchema={validationSchema}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setShowError(false);
            setSubmitting(true);

            const requestData = {
              body: {
                username: values.username,
                email: values.email,
                code: values.code,
                firstName: values.firstName,
                lastName: values.lastName
              }
            }
            try {
              const data = await API.post('temperaturetrackapi', '/register', requestData)
              console.log(data);
              setSubmitting(false);
              // setUsername(result.data.username);
              // setGroup(result.data.group);
              // setOnboardingMessage(result.data.onboarding_message)
              setEmail(values.email);
              setShowCompleteModal(true);
            } catch (error) {
              console.log(error);
              console.log(error.response.data.message)
              setErrorMessage(error.response.data.details.message);
              setShowError(true);
            }
          }}
        >
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            
            <Row>
                <Col>
                  <Form.Group controlId="formUsername">
                  <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      autoComplete={"username"}
                      placeholder="example-user-42"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      isInvalid={errors.username && touched.username}
                      isValid={!errors.username && touched.username}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.username}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            <Row>
              <Col>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email Address:</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="john@example.com"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isInvalid={errors.email && touched.email}
                      isValid={!errors.email && touched.email}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formEmailConfirm">
                    <Form.Label>Confirm Email Address:</Form.Label>
                    <Form.Control
                      type="emailConfirm"
                      name="emailConfirm"
                      placeholder="john@example.com"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.emailConfirm}
                      isInvalid={errors.emailConfirm && touched.emailConfirm}
                      isValid={!errors.emailConfirm && touched.emailConfirm}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.emailConfirm}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    autoComplete={"firstName"}
                    placeholder="John"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    isInvalid={errors.firstName && touched.firstName}
                    isValid={!errors.firstName && touched.firstName}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.firstName}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
                <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name:</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    autoComplete={"lastName"}
                    placeholder="Smith"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    isInvalid={errors.lastName && touched.lastName}
                    isValid={!errors.lastName && touched.lastName}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                  }}>
                  {errors.lastName}
                  </Form.Control.Feedback>
                  {/* <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback> */}
                </Form.Group>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col>
                <Form.Group controlId="formGroupAccessCode">
                <Form.Label>Access Code:</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    // disabled={true}
                    disabled={lockAccessCode}
                    style={{textTransform:'uppercase'}}
                    // disabled={isSubmitting}
                    placeholder="example22"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                    isInvalid={errors.code && touched.code}
                    isValid={!errors.code && touched.code}
                  />
                  <Form.Control.Feedback type="invalid" style={{
                          color: '#dc3545',
                          fontSize: '.8em',
                        }}>
                    {errors.code}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <FormGroup controlId="agreeToTerms">
              <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                  <Form.Check 
                  label={`I agree with the`} name="acceptTerms" value={values.acceptTerms} onChange={handleChange}></Form.Check>
                  <a href='/tos' target='_blank' rel="noopener noreferrer">Terms of Service</a> 
                  {errors.acceptTerms && touched.acceptTerms ? (
                    <div style={{
                      color: '#dc3545',
                      // padding: '.5em .2em',
                      // height: '1em',
                      fontSize: '.8em',
                    }}>{errors.acceptTerms}</div>
                  ): <div style={{
                    color: '#dc3545',
                    // padding: '.5em .2em',
                    // height: '1em',
                    fontSize: '.8em',
                  }}><br/></div>}   
                </div>
            </FormGroup>
            <Alert show={showError} variant="danger">
            <Alert.Heading>Error Signing Up</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
            </Alert>
            <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
                Sign Up
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  hidden={!isSubmitting}
                  style={{marginLeft: 5}}
                />
                <span className="sr-only">Loading...</span>
            </Button>
          </Form>
            )}
        </Formik>
        <Modal
          show={showCompleteModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton={false}>
            <Modal.Title>Welcome to PWT!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <>
            { group &&
              <>
                <p>You've joined PWT on behalf of: <b>{group}</b>.</p>
                <p>Your account has been automatically added as a member of this group. As such, this group will be able to see your general usage of PWT. They will not be able to see exact health data.</p>
              </>
            }
            <p>A welcome email has been sent to {email}. Use the information provided in this email to sign in for the first time. Once signed in, you'll be able to set your password.</p>
            { onboardingMessage != null &&
            <p><b>Message from {group}</b>: "{onboardingMessage}"</p>
            }
            <hr/>
            <p>For any support needs, please give PWT customer support a call at <b>888-997-0798</b>. If a representative is unavailable, please leave a voicemail and we will reach back out to you as soon as possible.</p>
          </>
          </Modal.Body>
          <Modal.Footer>
            <Button className='pwt-button' onClick={() => CompleteSignIn()}>Go to Sign In</Button>
          </Modal.Footer>
        </Modal>
      </div>         
  );
}


const SignUpAccessCodeForm = compose(
  withRouter,
)(SignUpAccessCodeModule);

export { SignUpAccessCodeForm };

export default SignUpAccessCodePage;