import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { API, Auth } from 'aws-amplify'

import { Alert, Button, Container, ListGroup } from "react-bootstrap";

import LoadingOverlay from 'react-loading-overlay';
import { Helmet } from 'react-helmet';

function GroupPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
          <title>Groups | PWT</title>
          <meta name="description" content="View your groups." />
      </Helmet>   
      <GroupPageBase></GroupPageBase>
    </Container>               
  );
}



function GroupPageBase(props) {
  
  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Manage Groups</h1>
      <GroupsModule></GroupsModule>
    </div>   
  );    
}

function GroupsModule() {
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showBusy, setShowBusy] = useState(false);

  const [groups, setGroups] = useState([]);
  

  useEffect(() => {
    async function fetchGroups() {
      setShowBusy(true);
      setShowError(false);
      setShowBusy(true);
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const requestData = {
          headers: {
          Authorization: token
          },
          body: {
          }
      }
      let data = null;
      try {
          data = await API.post('temperaturetrackapi', '/groups', requestData)
      } catch (error) {
          console.log(error);
      }
      let joinedGroups = []
      if (data?.groups.length > 0) {
          joinedGroups = data?.groups;
      }

      let groupsObject = [];
      let numberOfGroups = joinedGroups.length;
      for (let groupCount = 0; groupCount < numberOfGroups; groupCount++) {
        groupsObject.push({name: joinedGroups[groupCount].group_id, public_name: joinedGroups[groupCount].name, is_manager: false})
        let isManager = joinedGroups[groupCount].role === 'manager' ? true : false;
        groupsObject[groupCount].is_manager = isManager;

      }


      setGroups(groupsObject)
      setShowBusy(false);

    }
    fetchGroups();
  }, []);



    return(
      <LoadingOverlay
      active={showBusy}
      spinner
      // text='Loading group data...'
      styles={{
        overlay: (base) => ({
          ...base,
        })
      }}
      >
      <div>       
        <div>
          <h2>Groups You're In</h2>
          <p>Managers of these groups can see general information about your temperature data such as when your updates are submitted and if you have an elevated temperature. <b>Exact temperatures and other health metrics are not shared</b>.</p>
          <ListGroup>
            {groups.map(listitem => (
              <ListGroup.Item key={listitem.name}>
                {(listitem.is_manager) 
                ?
                  <>
                  {listitem.public_name} ({listitem.name})
                  {<br/>}
                  <Link to={`/groups/view/${listitem.name}`}>View Management Report</Link>
                  </>
                :
                  <>
                  {listitem.public_name} ({listitem.name})
                  </>
                }
                
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
       
          <Alert show={showError} variant="danger">
          <div style={{paddingTop:20}}>
              <Alert.Heading>Error Fetching Groups</Alert.Heading>
              <p>{errorMessage}</p>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => setShowError(false)} variant="outline-danger">
                  Dismiss
                </Button>
              </div>
          </div>
          </Alert>
    </div>
    </LoadingOverlay>
  ); 
  
}
  
export default GroupPage;
