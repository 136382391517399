import React, {useState} from 'react';
import { Link } from 'react-router-dom';

import { Auth } from 'aws-amplify'

import * as ROUTES from '../../constants/routes';
import { Alert, Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet';


const PasswordForgetPage = (props) => (
    <Container style={{padding:0}}>
      <Helmet>
        <title>Reset Password | PWT</title>
        <meta name="description" content="Trouble signing in? Reset your password here."/>
      </Helmet> 
      <PasswordForgetFormBase></PasswordForgetFormBase>
    </Container>
);

const validationSchemaUsername = Yup.object().shape({
  username: Yup.string()
  .min(2, "Username must have at least 2 characters.")
  .max(64, "Username can't be longer than 64 characters.")
  .required("Username is required."),
});

const validationSchemaChoosePassword = Yup.object().shape({
  token: Yup.string()
  // .min(6,"Password must be at least 6 characters.")   
  .required("A verification code is required.")
  .matches(
    /^[0-9]+/,
    "A verification code may only contain numbers."
  ),
  newPassword: Yup.string()
  .min(8,"Password must be at least 8 characters.")
  .max(99, "Password must be at most 99 characters.")   
  .required("You must choose a password.")
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`])\S{8,99}$/,
    "Passwords must contain an uppercase letter, lowercase letter, number, and special character."
  ),
  newPasswordConfirm: Yup.string()
  .required("Please confirm the password.")
  .label('Confirm password')
  .test('passwords-match', 'Passwords must match.', function(value) {
    return this.parent.newPassword === value;
  }),
});

function PasswordForgetFormBase(props) {
  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, padding:15}}>     
      <h1>Reset Password</h1>
      <p>Use this form to reset your password. Please provide your username and a recovery email will be delivered.</p>
      <ResetPasswordForm/>
      <div style={{paddingTop:10}}>
        <Link to={ROUTES.SIGN_IN}>Return to sign in.</Link>
      </div>
    </div>
  );
}

function ResetPasswordForm(){
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  
  const [username, setUsername] = useState("");
  const [deliveryResponse, setDeliveryReponse] = useState(null);

  const [formState, setFormState] = useState("username");



  if (formState === "username") {
    return(
    
      <div>     
        <Formik
            key={'enterUsername'}
            initialValues={{ username:""}}
            validationSchema={validationSchemaUsername}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                // When button submits form and form is in the process of submitting, submit button is disabled
                setSubmitting(true);
                setShowError(false);
                // Send confirmation code to user's email
                Auth.forgotPassword(values.username)
                .then(data => {
                  console.log(data)
                  setUsername(values.username)
                  setDeliveryReponse(data?.CodeDeliveryDetails);
                  setFormState("verification");
                })
                .catch(error => {
                  let code = error.code;
                  console.log(code);
                  let message = ""
                  switch (code) {
                    case "NotAuthorizedException":
                      message = "This account's password cannot be reset at this time. This is likely due to the account not successfully signing in for the first time yet. Please contact support for assistance."
                      break;
                    case "LimitExceededException":
                      message = "Attempt limit exceeded, please try after some time.";
                      break;
                    case "InvalidParameterException":
                      message = "This account's password cannot be reset as there is no registered/verified email or phone number associated with the account. Please contact support for assistance.";
                      break;
                    case "UserNotFoundException":
                      message = "An account by the specified username does not exist.";
                      break;
                    default:
                      message = "This account's password cannot be reset at this time. Please contact support for assistance."
                      break;
                  }
                  console.log('error signing in', error);
                  setErrorMessage(message);
                  setShowError(true);
                });
                setSubmitting(false);
            }}
          >
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <Row>
                <Col>
                  <Form.Group controlId="formUsername">
                  <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="example-user-42"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isInvalid={errors.username && touched.username}
                      isValid={!errors.username && touched.username}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.username}
                    </Form.Control.Feedback>
                    {/* <Form.Control.Feedback type="valid">Usernames are not case-sensitive.</Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              <Alert show={showSuccess} variant="success">
                <Alert.Heading>Password Updated</Alert.Heading>
                    <p>{successMessage}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                    <Button onClick={() => setShowSuccess(false)} variant="outline-success">
                      Dismiss
                    </Button>
                </div>
              </Alert>
              <Alert show={showError} variant="danger">
                <Alert.Heading>Failed to Request Password Reset</Alert.Heading>
                <p>{errorMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
              </Alert>
            <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
              {/* {isSubmitting ? 'Loading…' : 'Request Reset'} */}
              Request Reset
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!isSubmitting}
                style={{marginLeft: 5}}
              />
              <span className="sr-only">Loading...</span>
            </Button>
          </Form>
        )}
        </Formik>
      </div>
    );
  } else if (formState === "verification") {
    return(
      <div>      
        <h2>An email was sent to {deliveryResponse?.Destination}.</h2>
        <h3>Please enter the code below to verify your account ownership.</h3>
        <Formik
          key={'choosePassword'}
          initialValues={{token:"", newPassword:"", newPasswordConfirm:""}}
          validationSchema={validationSchemaChoosePassword}
          onSubmit={ async (values, {setSubmitting, resetForm}) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              setShowError(false);
              Auth.forgotPasswordSubmit(username, values.token, values.newPassword)
              .then(data => {
                console.log(data)
                setFormState("success");
              })
              .catch(error => {
                let code = error.code;
                console.log(code);
                let message = ""
                switch (code) {
                  case "NotAuthorizedException":
                    message = "This account's password cannot be reset at this time. This is likely due to the account not successfully signing in for the first time yet. Please contact support for assistance."
                    break;
                  case "LimitExceededException":
                    message = "Attempt limit exceeded, please try after some time.";
                    break;
                  case "CodeMismatchException":
                    message = "Invalid verification code provided. Please try again.";
                    break;
                  case "InvalidPasswordException":
                    message = "Password does not meet requirements. Passwords must be between 8 and 99 characters long and contain an uppercase letter, lowercase letter, number, and special character.";
                    break;
                  default:
                    message = "This account's password cannot be reset at this time. Please contact support for assistance."
                    break;
                }
                console.log('error signing in', error);
                setErrorMessage(message);
                setShowError(true);
              });
              setSubmitting(false);
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <Row>
              <Col>
              <Form.Group controlId="formToken">
              <Form.Label>Verification Code:</Form.Label>
                <Form.Control
                  type="text"
                  name="token"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="one-time-code"
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.token}
                  isInvalid={errors.token && touched.token}
                  isValid={!errors.token && touched.token}
                />
                <Form.Control.Feedback style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }} type="invalid">
                  {errors.token}
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback type="valid">Valid token.</Form.Control.Feedback> */}
              </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formNewPassword">
                <Form.Label>New Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  autoComplete="new-password"
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  isInvalid={errors.newPassword && touched.newPassword}
                  isValid={!errors.newPassword && touched.newPassword}
                />
                <Form.Control.Feedback type="invalid" style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>
                  {errors.newPassword}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback>
              </Form.Group>
              </Col>
              <Col>
              <Form.Group controlId="formNewPasswordConfirm">
              <Form.Label>Confirm:</Form.Label>
                <Form.Control
                  type="password"
                  name="newPasswordConfirm"
                  placeholder="********"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPasswordConfirm}
                  isInvalid={errors.newPasswordConfirm && touched.newPasswordConfirm}
                  isValid={!errors.newPasswordConfirm && touched.newPasswordConfirm}
                />
                <Form.Control.Feedback style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }} type="invalid">
                  {errors.newPasswordConfirm}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback>
              </Form.Group>
              </Col>
            </Row>
            <Alert show={showError} variant="danger">
                <Alert.Heading>Error Signing In</Alert.Heading>
                <p>{errorMessage}</p>
                <hr/>
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
            </Alert>
            <Button className='pwt-button' block size='lg' type="submit">
              Update Password
            </Button>
          </Form>
        )}
        </Formik>
      </div>  
    );
  } else if (formState === "success") {
      return (
        <div>      
          <h2>Your password has been successfully updated. Please sign in.</h2>
        </div> 
      );
  } else {
    return null;
  }
  
}


export default PasswordForgetPage;