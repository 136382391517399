import React, { useState } from 'react';
import { compose } from 'recompose';

import { withRouter, Link } from 'react-router-dom';

import { API } from 'aws-amplify'

import * as ROUTES from '../../constants/routes';
import { Alert, Button, Container, Form, FormGroup, Row, Col, Spinner } from "react-bootstrap";

import { Formik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet';



const legacyLoginSchema = Yup.object().shape({
  username: Yup.string()
  .min(2, "Username must have at least 2 characters.")
  .max(64, "Username can't be longer than 64 characters.")
  .required("Username is required."),
  password: Yup.string()
  .min(6,"Passwords must be at least 6 characters.")  
  .required("Password is required."),
});

const validationSchema = Yup.object().shape({
  username: Yup.string()
  .min(2, "Username must have at least 2 characters.")
  .max(64, "Username can't be longer than 64 characters.")
  .required("Username is required.")
  .matches(
    /^[a-zA-Z0-9]+([_ -]?[a-zA-Z0-9])*$/,
    "Username may contain only letters and numbers, optionally separated by underscores (_) or dashes (-)."
  ),
  email: Yup.string()
  .email("Must be a valid email address.")
  .required("Email is required."),
  emailConfirm: Yup.string()
    .required("Please confirm your email address.")
    .label('Confirm email')
    .test('emails-match', 'Emails must match.', function(value) {
      return this.parent.email === value;
  }),
  licenseEmail: Yup.string()
  .email("Must be a valid email address.")
  .required("License Email is required."),
  licenseKey: Yup.string()
  .required("License Key is required.")
  .label('License Key')
  .matches(
    /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/,
    "Must conform to the specified license key format."
  ),
  acceptTerms: Yup.bool()
  .oneOf([true], 'Please read and accept the ToS.'),
});


const SignUpMigrationPage = (props) => (
    <Container style={{padding:0}}>
        <Helmet>
          <title>Migrate Your Account | PWT</title>
          <meta name="description" content="Import your account from the legacy version of PWT."/>
          <meta name="robots" content="noindex"/>
        </Helmet>    
        <SignUpMigrationBase></SignUpMigrationBase>
    </Container>               
);


function SignUpMigrationBase(props) {

    return(
      <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Import Your Account</h1>
        <h2>Use Your Existing Credentials</h2>
        <p>Use your sign in credentials from the legacy version of PWT to migrate your account!</p>
        <SignUpMigrationForm></SignUpMigrationForm>
        <div style={{paddingTop:10}}>
          <Link to={ROUTES.SIGN_IN}>Already have an account? Sign in.</Link>
          <br/>
          <a href='https://warningtemperature.health/shop' target='_blank' rel="noopener noreferrer">Need a license key? Purchase one today.</a>
        </div>
      </div>  
  );
}

function SignUpMigrationModule(props) {
  const [showError, setShowError] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [formState, setFormState] = useState("import");

  if (formState === "import") {
    return (
      <div>       
        <Formik
          initialValues={{username:"", password: ""}}
          validationSchema={legacyLoginSchema}
          onSubmit={ async (values, {setSubmitting, resetForm}) => {
            setSubmitting(true);
            setShowError(false);
            const requestData = {
              body: {
                username: values.username,
                password: values.password,
              }
            }
            try {
              await API.post('temperaturetrackapi', '/register', requestData)
              setFormState("success");
            } catch (error) {
              console.log(error);
              console.log(error.response.data.message)
              setErrorMessage(error.response.data.details.message);
              setShowError(true);
            }
          }}
          >
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <Row>
                <Col>
                  <Form.Group controlId="formUsername">
                  <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      autoComplete={"username"}
                      placeholder="example-user-42"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      isInvalid={errors.username && touched.username}
                      isValid={!errors.username && touched.username}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.username}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formPassword">
                  <Form.Label>Password:</Form.Label>
                    <Form.Control
                      type="text"
                      name="password"
                      autoComplete={"password"}
                      placeholder="********"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      isInvalid={errors.password && touched.password}
                      isValid={!errors.password && touched.password}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.password}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              
              <Alert show={showError} variant="danger">
                <Alert.Heading>Error Signing Up</Alert.Heading>
                <p>{errorMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
                Start Migration
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  hidden={!isSubmitting}
                  style={{marginLeft: 5}}
                />
                <span className="sr-only">Loading...</span>
              </Button>
          </Form>
            )}
        </Formik>
      </div>  
    );
  }
  else if (formState === "signup") {
    return(
      <div>       
          <Formik
            initialValues={{username:"", email: "", emailConfirm: "", licenseEmail:"", licenseKey:"", acceptTerms:false}}
            validationSchema={validationSchema}
            onSubmit={ async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true);
              setShowError(false);
              const requestData = {
                body: {
                  username: values.username,
                  email: values.email,
                  licenseEmail: values.licenseEmail,
                  license: values.licenseKey,
                }
              }
              try {
                await API.post('temperaturetrackapi', '/register', requestData)
                setFormState("success");
              } catch (error) {
                console.log(error);
                console.log(error.response.data.message)
                setErrorMessage(error.response.data.details.message);
                setShowError(true);
              }
            }}
          >
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <Row>
                <Col>
                  <Form.Group controlId="formUsername">
                  <Form.Label>Username:</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      autoComplete={"username"}
                      placeholder="example-user-42"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      isInvalid={errors.username && touched.username}
                      isValid={!errors.username && touched.username}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.username}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">Be sure to write this down. Do not use personally identifiable information.</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formEmail">
                      <Form.Label>Email Address:</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="john@example.com"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        isInvalid={errors.email && touched.email}
                        isValid={!errors.email && touched.email}
                      />
                      <Form.Control.Feedback type="invalid" style={{
                              color: '#dc3545',
                              fontSize: '.8em',
                            }}>
                        {errors.email}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formEmailConfirm">
                      <Form.Label>Confirm Email Address:</Form.Label>
                      <Form.Control
                        type="emailConfirm"
                        name="emailConfirm"
                        placeholder="john@example.com"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.emailConfirm}
                        isInvalid={errors.emailConfirm && touched.emailConfirm}
                        isValid={!errors.emailConfirm && touched.emailConfirm}
                      />
                      <Form.Control.Feedback type="invalid" style={{
                              color: '#dc3545',
                              fontSize: '.8em',
                            }}>
                        {errors.emailConfirm}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col>
                  <Form.Group controlId="formLicenseEmail">
                    <Form.Label>License Email:</Form.Label>
                    <Form.Control
                      type="email"
                      name="licenseEmail"
                      placeholder="john@example.com"
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.licenseEmail}
                      isInvalid={errors.licenseEmail && touched.licenseEmail}
                      isValid={!errors.licenseEmail && touched.licenseEmail}
                    />
                    <Form.Control.Feedback type="invalid" style={{
                            color: '#dc3545',
                            fontSize: '.8em',
                          }}>
                      {errors.licenseEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formLicenseKey">
                      <Form.Label>License Key:</Form.Label>
                      <Form.Control
                        type="text"
                        name="licenseKey"
                        placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.licenseKey}
                        isInvalid={errors.licenseKey && touched.licenseKey}
                        isValid={!errors.licenseKey && touched.licenseKey}
                        style={{textTransform:'uppercase'}}
                      />
                      <Form.Control.Feedback type="invalid" style={{
                              color: '#dc3545',
                              fontSize: '.8em',
                            }}>
                        {errors.licenseKey}
                      </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
                
                <FormGroup controlId="agreeToTerms">
                  <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                    <Form.Check 
                      label={`I agree with the`} 
                      name="acceptTerms" 
                      disabled={isSubmitting} 
                      value={values.acceptTerms} 
                      onChange={handleChange}>
                    </Form.Check>
                    <a href='/terms-of-service' target='_blank' rel="noopener noreferrer">Terms of Service</a> 
                    {errors.acceptTerms && touched.acceptTerms ? (
                      <div style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>{errors.acceptTerms}</div>
                    ): <div style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}><br/></div>}   
                  </div>
              </FormGroup>
              <Alert show={showError} variant="danger">
                <Alert.Heading>Error Signing Up</Alert.Heading>
                <p>{errorMessage}</p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
              </Alert>
              <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
                Sign Up
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  hidden={!isSubmitting}
                  style={{marginLeft: 5}}
                />
                <span className="sr-only">Loading...</span>
              </Button>
          </Form>
            )}
        </Formik>
      </div>  
    );
  } else if (formState === "success") {
    return (
      <div>      
        <h2>Welcome to PWT!</h2>
        <h3>Check your email for your temporary password in order to sign in for the first time.</h3>
      </div> 
    );
} else {
  return null;
}
  
}

const SignUpMigrationForm = compose(
  withRouter,
)(SignUpMigrationModule);

export { SignUpMigrationForm };

export default SignUpMigrationPage;