import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

// import { useTransition, animated } from 'react-spring'

import HomePage from '../../pages/Home';
import AccountPage from '../../pages/Account';
import GroupsPage from '../../pages/Groups';
import GroupViewPage from '../../pages/GroupView';

import TemperatureProfile from '../../pages/TemperaturePofile';
import NotFoundPage from '../../pages/404';
import EditHistoryPage from '../../pages/History';
import ToSPage from '../../pages/ToS';
import PrivacyPolicyPage from '../../pages/PrivacyPolicy';
import AboutUsPage from '../../pages/AboutUs';


function AuthSwitch(props) {
  return (
    <Switch>
      <Route exact path={ROUTES.HOME} component={HomePage} />
      <Route exact path={ROUTES.PROFILE} component={TemperatureProfile} />
      <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route exact path={ROUTES.HISTORY} component={EditHistoryPage} />

      <Route exact path={ROUTES.GROUPS} component={GroupsPage} />
      <Route exact path={ROUTES.GROUPS_VIEW} component={GroupViewPage} />


      <Route exact path={ROUTES.TOS} component={ToSPage} />
      <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />

      <Route exact path={ROUTES.ABOUT_US} component={AboutUsPage} />

      <Route exact path={ROUTES.RESET_PASSWORD}>
        <Redirect to={ROUTES.HOME} />
      </Route>
      <Route exact path={ROUTES.SIGN_UP_KIT}>
        <Redirect to={ROUTES.HOME} />
      </Route>
      <Route exact path={ROUTES.SIGN_UP}>
        <Redirect to={ROUTES.HOME} />
      </Route>
      <Route exact path={ROUTES.SIGN_UP_GROUP}>
        <Redirect to={ROUTES.HOME} />
      </Route>

      <Route component={NotFoundPage} />
    </Switch>
  )
}

export default AuthSwitch;
