import React, { useEffect, useState } from 'react'

// import { BrowserRouter as Router } from 'react-router-dom';

import { AuthContext } from '../AuthContext';

import Header from '../Header';

import AuthSwitch from '../MainSwitch';
import NonAuthSwitch from '../NonAuthSwitch';

import { Auth, Hub } from 'aws-amplify'

function AuthNavigator() {
  const [initializing, setInitializing] = useState(true)
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data
      console.log('A new auth event has happened: ', data)
      switch (payload.event) {
        case 'signIn':
            console.log('user signed in');
            setUser(payload.data);
            break;
        case 'signUp':
            console.log('user signed up');
            break;
        case 'signOut':
            console.log('user signed out');
            setUser(null);
            break;
        case 'signIn_failure':
             console.error('user sign in failed');
             setUser(null)
            break;
        case 'tokenRefresh':
            console.log('token refresh succeeded');
            getUser();
            break;
        case 'tokenRefresh_failure':
            console.error('token refresh failed');
            setUser(null);
            break;
        case 'configured':
            console.log('the Auth module is configured');
            break;
        default:
          break;
      }
    })
  }, [])

  async function getUser() {
    console.log("Get User");
    try {
      let test = await Auth.currentAuthenticatedUser();
      setUser(test);
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    async function loadUser() {
      await getUser();
      setLoading(false);
    }
    loadUser()
  }, [])

  async function reloadUser(offlineStatus) {
    console.log("Reload User")
  }
  
  let userObject = {
    user: user,
    reload: () => reloadUser(),
  }

  return (  
    <>
        {loading
        ?
          // <Loading/>
          null
          // <p>asdf</p>
        :
        <>
          {userObject.user ? (
            <AuthContext.Provider value={userObject}>
              {/* <Router> */}
                <Header></Header>
                <AuthSwitch/>
              {/* </Router> */}
            </AuthContext.Provider>
            ) : (
            <AuthContext.Provider value={userObject}>
            {/* <Router> */}
                <Header></Header>
                <NonAuthSwitch/>
            {/* </Router> */}
            </AuthContext.Provider>
          )}
        </>
        }
    </>
  );
}

export default AuthNavigator;