import React from 'react';
import { Route, Switch } from 'react-router-dom';

import SignUpMigrationPage from '../../pages/SignUpMigration';

import SignUpAnonymousPage from '../../pages/SignUp';
import SignUpAnonymousKitPage from '../../pages/SignUpKit';
// import SignUpTrialPage from '../../pages/SignUpTrial';
// import RedeemTrialPage from '../../pages/RedeemTrial';
// import RedeemGroupPage from '../../pages/RedeemGroup';
import SignUpGroupPage from '../../pages/SignUpAccessCode';

import SignInAnonymousPage from '../../pages/SignIn';
import PasswordForgetPage from '../../pages/ResetPassword';

import ToSPage from '../../pages/ToS';
import PrivacyPolicyPage from '../../pages/PrivacyPolicy';
import AboutUsPage from '../../pages/AboutUs';

import HiddenPage from '../../pages/HiddenPage';

import * as ROUTES from '../../constants/routes';

function NonAuthSwitch(props) {
  return(
    <Switch >
      <Route exact path={ROUTES.SIGN_IN} component={SignInAnonymousPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpAnonymousPage} />
      <Route exact path={ROUTES.SIGN_UP_KIT} component={SignUpAnonymousKitPage} />
      <Route exact path={ROUTES.SIGN_UP_GROUP} component={SignUpGroupPage} />

      <Route exact path={ROUTES.SIGN_IN} component={SignInAnonymousPage} />
      <Route exact path={ROUTES.RESET_PASSWORD} component={PasswordForgetPage} />

      <Route exact path={ROUTES.TOS} component={ToSPage} />
      <Route exact path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} />

      <Route exact path={ROUTES.ABOUT_US} component={AboutUsPage} />

      <Route exact path={ROUTES.SIGN_UP_MIGRATION} component={SignUpMigrationPage} />

      <Route component={HiddenPage} />
    </Switch>
  )
}

export default NonAuthSwitch;
