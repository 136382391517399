import React from 'react';

import { Container } from "react-bootstrap";

import LastUpdate from '../../components/LastUpdate'

import { Helmet } from 'react-helmet';

function TemperatureProfilePage(props) {
  return (
    <Container style={{padding:0}}>
      <Helmet>
          <title>Temperature Profile | PWT</title>
          <meta name="description" content="View your latest temperature assessment and group policy compliance status." />
      </Helmet>
      <LastUpdate></LastUpdate>     
    </Container>
  );  
}



// export default withAuthorization(condition)(TemperatureProfilePage);
export default (TemperatureProfilePage);
