import './App.css';
import React from 'react';
import AuthNavigator from './components/Navigation/AuthNavigator'
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <Router>
      <AuthNavigator/>
    </Router>
  );
}

export default (App)
