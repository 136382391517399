import React from 'react';

import { Container } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

import { SignInPageBase } from '../SignIn';

export default function HiddenPage() {    

  return(
    <Container style={{padding:0}}>
        <Helmet>
            <title>Sign In | PWT</title>
            <meta name="description" content="Update your temperature." />
            <meta name="robots" content="noindex"/>
        </Helmet>
        <SignInPageBase/>
    </Container>
  );
}



