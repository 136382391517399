export const SIGN_UP = '/signup';
export const SIGN_UP_KIT = '/kit/signup/:key?';
export const SIGN_UP_MIGRATION = '/account-migration';



export const REDEEM_GROUP = '/group/redeem/:code?';
export const SIGN_UP_GROUP = '/group/signup/:code?';

export const SIGN_IN = '/';
export const HOME = '/';
export const PROFILE = '/temperature-profile';
export const ACCOUNT = '/account';
export const GROUPS = '/groups';
export const GROUPS_VIEW = '/groups/view/:name?';
export const GROUPS_ADMIN = '/groups/admin/:name?';

export const ADMIN = '/admin/ark';
export const RESET_PASSWORD = '/reset-password';
export const ACCOUNT_IMPORT = '/import-account';
export const HISTORY = '/history';
export const TOS = '/tos';
export const PRIVACY_POLICY = '/privacy';
export const LEGAL_INFORMATION = '/legal';
export const ABOUT_US = '/about';




