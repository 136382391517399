import React from 'react';

import { Container } from 'react-bootstrap';

import { Helmet } from 'react-helmet';


export default function PrivacyPolicyPage(props) {    

  return(
    <Container style={{padding:0}}>
      <Helmet>
        <title>Privacy Policy | PWT</title>
        <meta name="description" content="Personal Warning Temperature's Privacy Policy."/>
      </Helmet> 
      <PrivacyPolicy></PrivacyPolicy>
    </Container>               
  );
}


function PrivacyPolicy(props) {

  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Personal Warning Temperature (PWT)</h1>
        <h2>Privacy Policy</h2>
        <p>Personal Warning Temperature (PWT) is a web application or mobile application that allows users to regularly upload their personal medical information, get statistics and basic recommendations, and share medical information with others which may help reduce the risk of spreading infectious diseases such as COVID-19. An employer may require employees to perform daily health monitoring and share results which may provide a safer work environment in the context of an epidemic or pandemic. If the user and their organization opt in, their medical information may be shared with medical researchers for the purpose of characterizing an infectious disease like COVID-19 which might help mitigate it.</p>
        <p>Medical information is personal and sensitive by nature, and it should be kept confidential and shared only for specific purposes relevant to limiting the spread of disease. Users who upload data to Personal Warning Temperature should understand how Warning Temperature, LLC protects, stores, and, if necessary, releases Personal Warning Temperature information. Relevant to user privacy, Warning Temperature, LLC provides the Personal Warning Temperature service under the following terms and conditions.</p>
        <ol>
          <li><b>Data collection:</b> Personal Warning Temperature data comes from users who upload it based on knowledge that it may be shared for the purpose of reducing the spread of infectious disease. Organizations may request or require personnel to perform daily health monitoring and share data in Personal Warning Temperature.</li>
          <li><b>User accounts:</b> In Personal Warning Temperature, users are associated with organizations. The first time a user enters information into Personal Warning Temperature, the user sets a personal identification number (PIN) which is subsequently required to upload or view data. Within the organization, there may be selected members of management who may access basic information for a set of users such as whether the user is normal, potentially getting sick, or has a fever and also whether the user has uploaded data recently. There may also be personnel, typically in Human Resources, who can access all uploaded data from every user. Organizations are bound by their own privacy policy.</li>
          <li><b>Data protection:</b>  Warning Temperature, LLC has engineered Personal Warning Temperature for confidentiality, integrity, and availability. We use current best practices in security to protect your data in transit and limit access to it. For example, we use encryption to protect your data in transit as you upload it to our server. The server is secured by standard authentication protocols. There are a limited number of Warning Temperature, LLC employees who have access to the server for maintenance, auditing, backup, and updates, and they are bound by this policy.</li>
          <li><b>Data storage:</b>  Each organization's data is stored in a separate database, and access to the database is limited to that organization's management and to a small number of Warning Temperature, LLC's employees for maintenance and updates.</li>
          <li><b>Data retention:</b>  Unless specifically requested, Warning Temperature, LLC does not delete user data while the Personal Warning Temperature service is provided.</li>
          <li><b>Data release:</b>  Warning Temperature, LLC releases a user's data only to (1) an organization in response to a request from management, or (2) to medical researchers after an organization's management and the user opts in. In the case of medical research, the user's data will be provided anonymously, without the associated user account, unless specifically requested. Despite policy and protections, Warning Temperature, LLC will notify organizations in the event of unauthorized access.</li>
        </ol>
    </div>   
  );
  
}
