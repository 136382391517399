import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import { Col, Row } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';


const PROVISIONAL_TEMPERATURE_FAHRENHEIT = 99;
const PROVISIONAL_TEMPERATURE_CELSIUS = 37.2222;

function TemperatureAssessmentModuleBase(props) {  
    const [showBusy, setShowBusy] = useState(false);
  
    const [measuredTemperature, setMeasuredTemperature] = useState(0);
  
    
    const [warningTemperature, setWarningTemperature] = useState('');
  
    const [provisionalWarningTemperature, setProvisionalWarningTemperature] = useState(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
  
    const [healthTitle, setHealthTitle] = useState('');
    const [healthMessage, setHealthMessage] = useState('');
  
    const [chartUnit, setChartUnit] = useState('℉');
      
    const [hasUpdatedPlottingUnit, setHasUpdatedPlottingUnit] = useState(false);
    const [preferredPlottingUnit, setPreferredPlottingUnit] = useState('fahrenheit');
  
    const assessmentData = props.assessment

    const [lastUpdateString, setLastUpdateString] = useState('');
  
  
    function fahrenheitToCelsius(fahrenheit: number) {
      return ((fahrenheit - 32.0) * (5.0 / 9.0 ))
    }
  
    function updatePlottingUnit(unit) {
      setHasUpdatedPlottingUnit(true);
      setPreferredPlottingUnit(unit);
    }
  
    function setNoDataStates() {
  
      setWarningTemperature(0);
      setProvisionalWarningTemperature(PROVISIONAL_TEMPERATURE_FAHRENHEIT);
      setChartUnit('℉');
    }  
  
    function TemperatureAssessmentBadge() {
      return (
            <Col>
              <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
              <Row>      
              <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                  {/* <h4>{`${healthTitle}`}</h4> */}
                  <p style={{fontSize: '1.4rem'}}>{`${healthTitle}`}</p>
                  <p><b>{`Measurement`}</b>{`: ${measuredTemperature} ${chartUnit}`}</p>
                  <p>{`${healthMessage}`}</p>
              </div>
              </Row>
              <Row>
                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", marginBottom: 15}}>
                    {(warningTemperature !== 0)
                      ? 
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", width: 150, height: 150, backgroundColor: 'darkgray', border: '10px solid #21436D', borderRadius: '50%'}}>
                                <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                                  {/* <h6><b>PWT</b></h6> */}
                                  <p style={{fontSize: '1.0rem'}}><b>{`PWT`}</b></p>
                                </Row>
                                <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                                  {/* <h4><b>{`${warningTemperature} ${chartUnit}`}</b></h4> */}
                                  <p style={{fontSize: '1.4rem'}}><b>{`${warningTemperature} ${chartUnit}`}</b></p>
                                </Row>
                                <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                                </Row>
                            </div>
                      : 
                          <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", width: 175, height: 175, backgroundColor: 'darkgray', border: '10px solid #21436D', borderRadius: '50%'}}>
                            <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                              {/* <h6><b>PWT</b></h6> */}
                              <p style={{fontSize: '1.0rem'}}><b>{`PWT`}</b></p>
                            </Row>
                            <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                              {/* <h4><b>{`${provisionalWarningTemperature} ${chartUnit}`}</b></h4> */}
                              <p style={{fontSize: '1.4rem'}}><b>{`${provisionalWarningTemperature} ${chartUnit}`}</b></p>
                            </Row>
                            <Row style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", height:'100%'}}>
                              <p><b>(provisional)</b></p>
                            </Row>
                          </div>
                      }
                </div>
              </Row>
              <Row>      
                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                  {(warningTemperature === 0)
                  ?
                  <p>Use this <i><b>provisional</b></i> temperature as a guide until you have enough temperature entries to create your own Personal Warning Temperature.</p>
                  :
                  null
                  }
                  <p><b>{`Updated`}</b>{`: ${lastUpdateString}`}</p>
                 
                </div>
              </Row>
              </div>
            </Col>
      );
    }
  
    

    
    useEffect(() => { 
      async function loadPlottingData() {

        function updateBadge() {
          let pwtLastUpdate = assessmentData;
          // console.log(pwtLastUpdate);
          let plottingUnit = ''
          if (hasUpdatedPlottingUnit === true) {
            plottingUnit = preferredPlottingUnit;
          } else {
            plottingUnit = 'fahrenheit';
          }
      
          let tempMeasuredTemperature = 0;
          let tempProvisionalWarningTemperaturePlotting = 0;
          let tempPersonalWarningTemperaturePlotting = 0;
          let tempStatusTitle = '';
          let tempStatusMessage = '';
          let tempChartUnit = '';
          let tempLastUpdateDate: Date;
          let tempLastUpdateDateString = '';
      
          tempMeasuredTemperature = pwtLastUpdate.temperature;
          tempPersonalWarningTemperaturePlotting = pwtLastUpdate.personal_warning_temperature

          tempStatusTitle = pwtLastUpdate.title;
          tempStatusMessage = pwtLastUpdate.message;
      
          tempLastUpdateDate = new Date((assessmentData.update_timestamp * 1000));
          tempLastUpdateDateString = tempLastUpdateDate.toLocaleString(undefined, { timeZoneName: 'short' });
          
          // Conversions
      
          if (plottingUnit === 'fahrenheit') {
            tempChartUnit = '℉';
            tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_FAHRENHEIT;
          } else if (plottingUnit === 'celsius') {
            tempChartUnit = '℃';
            tempProvisionalWarningTemperaturePlotting = PROVISIONAL_TEMPERATURE_CELSIUS;
    
            tempMeasuredTemperature = fahrenheitToCelsius(parseFloat(tempMeasuredTemperature));
            if (tempPersonalWarningTemperaturePlotting !== 0) {
              tempPersonalWarningTemperaturePlotting = fahrenheitToCelsius(parseFloat(tempPersonalWarningTemperaturePlotting));
            }
          }
          
          // Rounding
          tempMeasuredTemperature = Number(Math.round(parseFloat(tempMeasuredTemperature + 'e' + 2)) + 'e-' + 2);
          tempPersonalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempPersonalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
          tempProvisionalWarningTemperaturePlotting = Number(Math.round(parseFloat(tempProvisionalWarningTemperaturePlotting + 'e' + 2)) + 'e-' + 2);
      
          // Messages
          setMeasuredTemperature(tempMeasuredTemperature);
          setWarningTemperature(tempPersonalWarningTemperaturePlotting);
          setProvisionalWarningTemperature(tempProvisionalWarningTemperaturePlotting);
          setHealthTitle(tempStatusTitle);
          setHealthMessage(tempStatusMessage);
          setLastUpdateString(tempLastUpdateDateString);
      
          // Unit
          setChartUnit(tempChartUnit);
          if (plottingUnit !== preferredPlottingUnit) {
            setPreferredPlottingUnit(plottingUnit);
          }
          setShowBusy(false); 
        }

        setShowBusy(true);
        // Fetch up-to-date health metrics from the latest temperature entry
        if (assessmentData == null) {
            setNoDataStates();
            if (showBusy) {
              setShowBusy(false);
            }
          return;
        } else {
          updateBadge();
        }
        
      }
  
      loadPlottingData();
    }, [assessmentData, hasUpdatedPlottingUnit, preferredPlottingUnit, showBusy]);
    
      return (
        <LoadingOverlay
            active={showBusy}
            spinner
            // text='Refreshing...'
            styles={{
              overlay: (base) => ({
                ...base,
              })
            }}
          >
        <div>     
          {(assessmentData == null && showBusy === false)
            ? 
              <div>
                <p>No data found.</p>
                <p>Upload your first temperature to start building your temperature profile!</p>
              </div>
            : 
              <div>
                <div style={{marginTop: 15}}>
                  <p style={{fontSize: '1.4rem'}}>Temperature Assessment:</p>
                  <TemperatureAssessmentBadge></TemperatureAssessmentBadge>
                </div>
                <div style={{marginBottom: 15}}>
                  <label>Unit:</label>
                  <select className="form-control" id="plottingUnitSelector" value={preferredPlottingUnit} onChange={ (e) => updatePlottingUnit(e.target.value)}>
                    <option value="fahrenheit">Fahrenheit</option>
                    <option value="celsius">Celsius</option>
                  </select>
                </div>
              </div>
          } 
      </div>
      </LoadingOverlay> 
                    
      );
    
}


const TemperatureAssessmentModule = compose(
  )(TemperatureAssessmentModuleBase);
  
  export { TemperatureAssessmentModule };
