import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';
import { AuthContext } from  '../AuthContext'

import { API, Auth } from 'aws-amplify'

import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';

import SwiperCore, { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination]);

// var QRCode = require('qrcode.react');

function GreenScreenBannerModuleBase(props) {
    const [showBusy, setShowBusy] = useState(true);

    const assessmentData = props.assessment;
    const [banners, setBanners] = useState([])

    const [show, setShow] = useState(false);

    const [url, setUrl] = useState('');
    const [colorQr, setColorQr] = useState('green');
    const [messageQr, setMessageQr] = useState('PASS');
    const [showQr, setShowQr] = useState('flex');

    const [timestamp, setTimestamp] = useState();

    const handleClose = () => setShow(false);

    function displayBannerCode(data) {
        setShow(true);
    }

    async function generateBanners() {
        let pwtLastUpdate = assessmentData;
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken

        const requestData = {
            headers: {
                Authorization: token
            },
            body: {
            }
        }
        let data = null;
        try {
            data = await API.post('temperaturetrackapi', '/groups', requestData)
        } catch (error) {
            console.log(error.response.data.message)
        }
        let joinedGroups = []
        if (data?.groups.length > 0) {
            joinedGroups = data?.groups;
        }
        let tempBanners = [];
        if (joinedGroups.length > 0){
            for (let count = 0; count < joinedGroups.length; count++) {
                let group = joinedGroups[count].group_id;
                let data = {
                    public_name: joinedGroups[count].name
                }

                // Group information
                let groupName = data?.public_name != null ? data?.public_name : '';
                let groupPassMessage = data?.pass_message != null ? data?.pass_message : 'Safe to go to work!'
                let groupFailMessage = data?.fail_message != null ? data?.fail_message : 'Contact your manager!'
                let groupExpireMessage = data?.expire_message != null ? data?.expire_message : 'Please update your temperature!'
                let message = '';
                // Group rules
                let allowExtensions = data?.allow_extensions != null ? data?.allow_extensions : false;
                // User's data
                let tempStatusTitle = '';
                let tempStatusMessage = '';
                let tempStatus = '';
                let tempLastUpdateDate: Date;
                let tempLastUpdateDateString = '';
                let tempBannerExpirationDate: Date;
                let tempBannerExpirationDateString = ''
                let tempExtensionDate: Date;
                let tempExtensionDateString = ''
                let tempBannerType = '';

                if (pwtLastUpdate != null) {
                    tempStatusTitle = pwtLastUpdate.title;
                    tempStatusMessage = pwtLastUpdate.message;
                    tempStatus = pwtLastUpdate.status;
                    tempLastUpdateDate = new Date((pwtLastUpdate.update_timestamp * 1000));

                    if (allowExtensions == true){
                        let extension = pwtLastUpdate.extension;
                        if (extension != null) {
                            tempLastUpdateDate = new Date((extension * 1000));
                        }
                    }
                    tempLastUpdateDateString = tempLastUpdateDate.toLocaleString(undefined, { timeZoneName: 'short' });

                    
                    // Banner time logic
                    tempBannerExpirationDate = new Date(tempLastUpdateDate.getTime() + (12 * 60 * 60 * 1000));
                    tempBannerExpirationDateString = tempBannerExpirationDate.toLocaleString(undefined, { timeZoneName: 'short' });
                    // Banner
                    if (tempStatus === 'Red' || tempStatus === 'Orange') {
                        tempBannerType = 'warning';
                        message = groupFailMessage;
                    } else if (new Date().getTime() >= tempBannerExpirationDate.getTime()) {
                        tempBannerType = 'expired';
                        message = groupExpireMessage;
                    } else {  // User is at the very least not red or orange, let's check symptoms if they have any
                        if (pwtLastUpdate.symptoms != null) {
                            if (pwtLastUpdate.symptoms.length > 0) {
                                tempBannerType = 'warning';
                                message = groupFailMessage;
                            } else {
                                tempBannerType = 'pass';
                                message = groupPassMessage;
                            } 
                        } else {
                            tempBannerType = 'pass';
                            message = groupPassMessage;
                        }
                    }
                } else {
                    tempBannerType = 'expired';
                    message = groupExpireMessage;
                }
                let groupBanner = {type: 'group', groupName : groupName, groupID: group, result: tempBannerType, lastUpdate: tempLastUpdateDateString, expiration: tempBannerExpirationDateString, message: message}
                tempBanners.push(groupBanner);
            }
            setBanners([...tempBanners])
        } else {
            // Group information
            let groupName = data?.public_name != null ? data?.public_name : '';
            let groupPassMessage = data?.pass_message != null ? data?.pass_message : 'Safe to go about your day!'
            let groupFailMessage = data?.fail_message != null ? data?.fail_message : 'There may be a problem!'
            let groupExpireMessage = data?.expire_message != null ? data?.expire_message : 'Please update your temperature!'
            let message = '';
            // Group rules
            let allowExtensions = data?.allow_extensions != null ? data?.allow_extensions : false;
            // User's data
            let tempStatusTitle = '';
            let tempStatusMessage = '';
            let tempStatus = '';
            let tempLastUpdateDate: Date;
            let tempLastUpdateDateString = '';
            let tempBannerExpirationDate: Date;
            let tempBannerExpirationDateString = ''
            let tempExtensionDate: Date;
            let tempExtensionDateString = ''
            let tempBannerType = '';

            if (pwtLastUpdate != null) {
                tempStatusTitle = pwtLastUpdate.title;
                tempStatusMessage = pwtLastUpdate.message;
                tempStatus = pwtLastUpdate.status;
                tempLastUpdateDate = new Date((pwtLastUpdate.update_timestamp * 1000));

                if (allowExtensions == true){
                    let extension = pwtLastUpdate.extension;
                    if (extension != null) {
                        tempLastUpdateDate = new Date((extension * 1000));
                    }
                }
                tempLastUpdateDateString = tempLastUpdateDate.toLocaleString(undefined, { timeZoneName: 'short' });

                
                // Banner time logic
                tempBannerExpirationDate = new Date(tempLastUpdateDate.getTime() + (12 * 60 * 60 * 1000));
                tempBannerExpirationDateString = tempBannerExpirationDate.toLocaleString(undefined, { timeZoneName: 'short' });
                // Banner
                if (tempStatus === 'Red' || tempStatus === 'Orange') {
                    tempBannerType = 'warning';
                    message = groupFailMessage;
                } else if (new Date().getTime() >= tempBannerExpirationDate.getTime()) {
                    tempBannerType = 'expired';
                    message = groupExpireMessage;
                } else {  // User is at the very least not red or orange, let's check symptoms if they have any
                    if (pwtLastUpdate.symptoms != null) {
                        if (pwtLastUpdate.symptoms.length > 0) {
                            tempBannerType = 'warning';
                            message = groupFailMessage;
                        } else {
                            tempBannerType = 'pass';
                            message = groupPassMessage;
                        } 
                    } else {
                        tempBannerType = 'pass';
                        message = groupPassMessage;
                    }
                }
            } else {
                tempBannerType = 'expired';
                message = groupExpireMessage;
            }

            let groupBanner = {type: 'individual', result: tempBannerType, lastUpdate: tempLastUpdateDateString, expiration: tempBannerExpirationDateString, message: message}
            tempBanners.push(groupBanner)
            setBanners([...tempBanners])
            // setBanners([]);
        }
    }

    function AssessmentBanner(props) {
        if (props.banner.type === 'group') {
            if (props.banner.result === 'pass') {
                return (
                    <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
                        <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px", border:`2px solid green`, backgroundColor:`green`}}>
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                <p style={{color:'white', fontSize: '1.8rem'}}>{props.banner.message}</p>
                                <p style={{color:'white', fontSize: '1.4rem'}}>{`${props.banner.groupName} (${props.banner.groupID})`}</p>
                            </div>
                            <hr style={{color:'white', backgroundColor:'white'}}/>
                            <p style={{color:'white'}}>{`Based on your group's policy, you are safe to proceed to your designated place. Share this screen to prove your status.`}</p>
                            <p style={{color:'white'}}>{`Valid Until: ${props.banner.expiration}`}</p>
                            {/* <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center", postition: 'absolute bottom 0'}}>
                                <Button className='pwt-button' variant="primary" onClick={() => { displayBannerCode(props.banner.result) } }>
                                Prove Your Status
                                </Button>
                            </div> */}
                        </div>  
                    </div>
                );
            } else if (props.banner.result === 'expired') {
                return (
                    <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
                        <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px", border:`2px solid gold`, backgroundColor:`gold`, height:'100%'}}>
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                    <p style={{color:'black', fontSize: '1.8rem'}}>{props.banner.message}</p>
                                    <p style={{color:'black', fontSize: '1.4rem'}}>{`${props.banner.groupName} (${props.banner.groupID})`}</p>
                            </div>
                            <hr style={{color:'black', backgroundColor:'black'}}/>
                            <p style={{color:'black'}}>{`A new temperature update is required to assess your group policy compliance.`}</p>
                            { (props.banner.expiration != '')
                            ?
                            <p style={{color:'black'}}>{`Last update recorded: ${props.banner.lastUpdate}`}</p>
                            :
                            <p style={{color:'black'}}>{`No updates received.`}</p>
                            }
                            {/* <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                    <Button className='pwt-button' variant="primary" onClick={() => { displayBannerCode(props.banner.result) } }>
                                    Prove Your Status
                                    </Button>
                            </div> */}
                        </div>
                    </div>
                );
            } else if (props.banner.result === 'warning') {
                return (
                    <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
                        <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px",  border:`2px solid gold`, backgroundColor:`gold`}}>
                            <div>
                                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                    <p style={{color:'black', fontSize: '1.8rem'}}>{props.banner.message}</p>
                                    <p style={{color:'black', fontSize: '1.4rem'}}>{`${props.banner.groupName} (${props.banner.groupID})`}</p>
                                </div>
                                <hr style={{color:'black', backgroundColor:'black'}}/>
                                <p style={{color:'black'}}>{`Based on your group's policy, you should contact your manager to discuss your next steps.`}</p>
                                <p style={{color:'black'}}>{`Valid Until: ${props.banner.expiration}`}</p>
                                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                    {/* <Button className='pwt-button' variant="primary" onClick={() => { displayBannerCode(props.banner.result) } }>
                                    Prove Your Status
                                    </Button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    null
                );
            }
        } else if (props.banner.type === 'individual') {
            if (props.banner.result === 'pass') {
                return (
                    <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 50}}>
                        <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px", border:`2px solid green`, backgroundColor:`green`}}>
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                <p style={{color:'white', fontSize: '1.8rem'}}>{props.banner.message}</p>
                            </div>
                            <hr style={{color:'white', backgroundColor:'white'}}/>
                            <p style={{color:'white'}}>{`Based on your temperature update, you are safe to proceed to your designated place.`}</p>
                            <p style={{color:'white'}}>{`Valid Until: ${props.banner.expiration}`}</p>
                        </div>  
                    </div>
                );
            } else if (props.banner.result === 'expired') {
                return (
                    <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
                        <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px", border:`2px solid gold`, backgroundColor:`gold`, height:'100%'}}>
                            <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                <p style={{color:'black', fontSize: '1.8rem'}}>{props.banner.message}</p>
                            </div>
                            <hr style={{color:'black', backgroundColor:'black'}}/>
                            <p style={{color:'black'}}>{`A new temperature update is required to assess your status.`}</p>
                            { (props.banner.expiration != '')
                            ?
                            <p style={{color:'black'}}>{`Last update recorded: ${props.banner.lastUpdate}`}</p>
                            :
                            <p style={{color:'black'}}>{`No updates received.`}</p>
                            }
                        </div>
                    </div>
                );
            } else if (props.banner.result === 'warning') {
                return (
                    <div style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}>
                        <div style={{display:`block`, paddingLeft:30, paddingRight:30, borderRadius:"25px",  border:`2px solid gold`, backgroundColor:`gold`}}>
                            <div>
                                <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                                    <p style={{color:'black', fontSize: '1.8rem'}}>{props.banner.message}</p>
                                </div>
                                <hr style={{color:'black', backgroundColor:'black'}}/>
                                <p style={{color:'black'}}>{`Based on your temperature update, you should continue to monitor your temperature and avoid close contact with others.`}</p>
                                <p style={{color:'black'}}>{`Valid Until: ${props.banner.expiration}`}</p>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    null
                );
            }
        } 
    }
    
    useEffect(() => { 
        async function configureBanner() {
            setShowBusy(true);
            await generateBanners();
            if (showBusy) {
                setShowBusy(false);
            }
        }
        configureBanner();
    }, [assessmentData]);
  
    if (showBusy) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </div>
        );
    } else {
        if (banners.length > 1) {
            return (
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    centeredSlides = {true}
                    navigation
                    pagination={{ clickable: true }}
                >
                    {banners.map((banner, element) => {
                        return <SwiperSlide key={element}><AssessmentBanner banner={banner}></AssessmentBanner></SwiperSlide>;
                    })}
                </Swiper> 
                //   {/* <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                //   <Modal
                //     show={show}
                //     onHide={handleClose}
                //     backdrop="static"
                //     keyboard={false}
                //     centered
                //     >
                //         <Modal.Header closeButton>
                //             <Modal.Title>Prove Your Status</Modal.Title>
                //         </Modal.Header>
                //         <Modal.Body>
                //         <div>
                //             <div style = {{display: showQr, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                //                 <p style={{color:colorQr, fontSize: '1.6rem'}}><b>{messageQr}</b></p>
                //                 <div onClick={() => window.open(url, '_blank')} style={{border:`10px solid ${colorQr}`, paddingBottom:0, paddingTop: 6, paddingLeft:6, paddingRight: 6}}>
                //                     <QRCode value={url} />
                //                 </div>
                //             </div>             
                //         </div>   
                //         </Modal.Body>
                //         <Modal.Footer>
                //         <Button className='pwt-button-secondary' onClick={handleClose}>
                //             Close
                //         </Button>
                //         </Modal.Footer>
                //     </Modal>
                     
                // </div> */}
              );
        } else if (banners.length == 1) {
            return (
                  <AssessmentBanner banner={banners[0]}></AssessmentBanner>
                //   {/* <div style={{display:'flex', flexDirection:"column", alignItems:'center', justifyContent:"center"}}>
                //   <Modal
                //     show={show}
                //     onHide={handleClose}
                //     backdrop="static"
                //     keyboard={false}
                //     centered
                //     >
                //         <Modal.Header closeButton>
                //             <Modal.Title>Prove Your Status</Modal.Title>
                //         </Modal.Header>
                //         <Modal.Body>
                //         <div>
                //             <div style = {{display: showQr, flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                //                 <p style={{color:colorQr, fontSize: '1.6rem'}}><b>{messageQr}</b></p>
                //                 <div onClick={() => window.open(url, '_blank')} style={{border:`10px solid ${colorQr}`, paddingBottom:0, paddingTop: 6, paddingLeft:6, paddingRight: 6}}>
                //                     <QRCode value={url} />
                //                 </div>
                //             </div>             
                //         </div>   
                //         </Modal.Body>
                //         <Modal.Footer>
                //         <Button className='pwt-button-secondary' onClick={handleClose}>
                //             Close
                //         </Button>
                //         </Modal.Footer>
                //     </Modal>
                     
                // </div> */}
              );
        } else {
            return (null);
        }
    }
    
    
  
}

const GreenScreenBannerModule = compose(
)(GreenScreenBannerModuleBase);

export { GreenScreenBannerModule };
