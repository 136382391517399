import React, {useContext, useEffect, useState} from 'react';

import { Button, Spinner } from 'react-bootstrap';

import { GreenScreenBannerModule } from '../GreenScreenBanner';
import { TemperatureAssessmentModule } from '../TemperatureAssessment'
import { TemperatureChartModule } from '../TemperatureChart'

import { AuthContext } from '../../components/AuthContext'

import { API, Auth } from 'aws-amplify'


function TemperatureProfileBase(props) {
  const user = useContext(AuthContext)

  const [showAssessment, setShowAssessment] = useState(true);
  const [showChart, setShowCart] = useState(false);
  
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // setShowBusy(true);
    async function getAssessment() {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const requestData = {
        headers: {
          Authorization: token
        },
        body: {
          last_update: true,
        }
      }
      try {
        const data = await API.post('temperaturetrackapi', '/assessment', requestData)
        setAssessmentData(data.Items[0]);
      } catch (error) {
        console.log(error);
        console.log(error.response.data.message)
      } finally {
        setLoading(false)
      }
      
    }
    getAssessment();
  }, []);

  if (loading) {
    return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Temperature Profile</h1>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />
        <span className="sr-only">Loading...</span>
      </div>
      
    </div>
    );
  } else {
    return (
      <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Temperature Profile</h1>
        <GreenScreenBannerModule assessment={assessmentData}/>
        <hr/>
        {
          // (user.assessmentDoc != null)
          (assessmentData != null)
          ?
            <div>
              <Button className='pwt-button' block size="sm" type="button" onClick={() => 
                setShowAssessment(!showAssessment)
              }
              >
              {(showAssessment === true)
              ? 'Hide Assessment'
              : 'View Assessment'
              }
            </Button>
            {
              (showAssessment)
              ?
                <TemperatureAssessmentModule assessment={assessmentData}/>
              :
                null
            }
            <Button className='pwt-button' block size="sm" type="button" onClick={() => 
                setShowCart(!showChart)
              }
              >
              {(showChart === true)
              ? 'Hide Chart'
              : 'View Chart'
              }
            </Button>
            {
              (showChart)
              ?
                <TemperatureChartModule assessment={assessmentData}/>
              :
                null
            }
            </div>
          :
          <div>
            <p>No data found.</p>
            <p>Upload your first temperature to start building your temperature profile!</p>
          </div>
        }
        
        
      </div>
    );
  }
  

}






export default TemperatureProfileBase;
