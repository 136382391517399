import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { Container } from 'react-bootstrap';

import { Helmet } from 'react-helmet';


export default function AboutUsPage(props) {    

  return(
    <Container style={{padding:0}}>
      <Helmet>
        <title>About Us | PWT</title>
        <meta name="description" content="Learn more about us and PWT. "/>
      </Helmet> 
      <AboutUs></AboutUs>
    </Container>               
  );
}


function AboutUs(props) {
  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>About Us</h1>
      <p>Personal Warning Temperature (PWT) is a product of Warning Temperature, LLC. 
        It was developed in response to the COVID-19 pandemic.
      </p>
      <p>As an affiliate company of
        {' '}
        <a href="https://idealinnovations.com" target="_blank" rel="noopener noreferrer">Ideal Innovations, Inc.</a>
        {', '} 
        innovation is at the forefront of our company. Our goal with PWT is to provide a simple, mathematics-based approach to enable 
        the potential early detection of illnesses. Learn about our approach and much more by visiting our 
        {' '}
        <a href="https://warningtemperature.health" target="_blank" rel="noopener noreferrer">home page</a>
        {'.'}
      </p>
      <p>For issues with the app, your account, or any other technical problems, email us at: 
        {' '}
        <a href="mailto:support@warningtemperature.health">support@warningtemperature.health</a>
        {'. '} 
      </p>
      <hr/>
      <div style={{textAlign:'center'}}>
        <address>
          Warning Temperature, LLC<br/>
          4401 Wilson Blvd Suite 210<br/>
          Arlington, VA 22203<br/>
          <a href="https://warningtemperature.health" target="_blank" rel="noopener noreferrer">
          warningtemperature.health</a><br/>      
        </address>
        <p>User experience, privacy, and security are fundamental to PWT.<br/>Learn more by reviewing our
        {' '}
        <a href={ROUTES.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        {' and '} 
        <a href={ROUTES.TOS} target="_blank" rel="noopener noreferrer">Terms of Service (ToS)</a>.
        </p>
          <span>&copy; 2020-2021 Warning Temperature, LLC &ndash; Patent Pending</span>
        </div>
  </div>
  );
  
}
