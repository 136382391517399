import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';

import { Auth } from 'aws-amplify'

import * as ROUTES from '../../constants/routes';

import { Alert, Button, Container, Form, Row, Col, Spinner, Modal } from "react-bootstrap";

import { Formik } from 'formik';

import * as Yup from 'yup';

import { Helmet } from 'react-helmet';

const SignInPage = (props) => (
  <Container style={{padding:0}}>
    <Helmet>
      <title>Sign In | PWT</title>
      <meta name="description" content="Sign in to your PWT account."/>
    </Helmet>       
    <SignInPageBase/>
  </Container>               
);





function SignInPageBase(props) {
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleClose = () => setShowSignUpModal(false);
  const handleShow = () => setShowSignUpModal(true);

  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Welcome to Personal Warning Temperature!</h1>
      <LandingHeader></LandingHeader>
      <SignInForm></SignInForm>
      <Modal
        show={showSignUpModal}
        onHide={handleClose}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Choose a Sign Up Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <>
        <p>Getting started with PWT is quick and easy. Select the sign up method that fits with what you have.</p>
        <Link to={ROUTES.SIGN_UP}>Digital License - purchased from the PWT online store.</Link>
        <br/>
        <Link to={"/kit/signup"}>Activation Card - part of the physical PWT Kit.</Link>
        {/* <br/> */}
        {/* <Link to={"/group/signup"}>Special Access Code - given to groups for mass onboarding.</Link> */}
        </>
        </Modal.Body>
      </Modal>
      <div style={{paddingTop:10}}>
        <Link to={ROUTES.RESET_PASSWORD}>Trouble signing in?</Link>
        <br/>
        <Button variant={'link'} style={{padding:0}} onClick={() => handleShow()}>
        Don't have an account? Sign up.
        </Button>
      </div>
    </div>         
  );
}

function LandingHeader(props) {
  return(
    <div>
      <p>PWT is a personal body temperature recording and analysis system for detecting potential illness before traditional symptoms appear. 
      {' '}
      <a href="https://warningtemperature.health" target="_blank" rel="noopener noreferrer">Learn more</a>
      {'.'}
      </p>
    </div>
  );
}


function SignInFormBase(props) {

  const validationSchemaSignIn = Yup.object().shape({
    username: Yup.string()
    .min(2, "Username must have at least 2 characters.")
    .max(64, "Username can't be longer than 64 characters.")
    .required("Your username is required."),
    password: Yup.string()
    .min(8,"Password must be at least 8 characters.")
    .max(99, "Password must be at most 99 characters.")   
    .required("Your password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`])\S{8,99}$/,
      "Passwords must contain an uppercase letter, lowercase letter, number, and special character."
    ),
  });
  
  
  const validationSchemaChoosePassword = Yup.object().shape({
    newPassword: Yup.string()
    .min(8,"Password must be at least 8 characters.")
    .max(99, "Password must be at most 99 characters.")   
    .required("You must choose a password.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`])\S{8,99}$/,
      "Passwords must contain an uppercase letter, lowercase letter, number, and special character."
    ),
    newPasswordConfirm: Yup.string()
    .required("Please confirm the password.")
    .label('Confirm password')
    .test('passwords-match', 'Passwords must match.', function(value) {
      return this.parent.newPassword === value;
    }),
  });


  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showSetNewPasswordForm, setShowSetNewPasswordForm] = useState(false);
  
  const [username, setUsername] = useState("")
  const [tempPassword, setTempPassword] = useState("")



  if (showSetNewPasswordForm === false) {
    return(
      <div>    
          <h2>Please sign in:</h2>   
          <Formik
            key={'signIn'}
            initialValues={{ username:"", password:""}}
            validationSchema={validationSchemaSignIn}
            onSubmit={ async (values, {setSubmitting, resetForm}) => {
                // When button submits form and form is in the process of submitting, submit button is disabled
                setSubmitting(true);
                setShowError(false);
                await Auth.signIn(values.username, values.password)
                .then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        setUsername(values.username);
                        setTempPassword(values.password);
                        setShowSetNewPasswordForm(true);
                    } else {
                       // Sign in success
                    }
                }).catch(error => {
                    let code = error.code;
                    console.log(error.message);
                    let message = ""
                    switch (code) {
                      case "NotAuthorizedException":
                        message = "Incorrect username or password. Please try again."
                        break;
                      case "LimitExceededException":
                        message = "Attempt limit exceeded, please try after some time.";
                        break;
                      case "InvalidPasswordException":
                        message = "Password does not meet requirements. Passwords must be between 8 and 99 characters long and contain an uppercase letter, lowercase letter, number, and special character.";
                        break;
                      case "UserNotFoundException":
                        message = "An account by the specified username does not exist.";
                        break;
                      default:
                        message = "Unable to sign in at this time. Please contact support for assistance."
                        break;
                    }
                    console.log('error signing in', error);
                    setErrorMessage(message);
                    setShowError(true);
                });
            }}
          >
            {/* Callback function containing Formik state and helpers that handle common form actions */}
          {( {values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting }) => (
            <Form onSubmit={handleSubmit} className="mx-auto">
              <Form.Group controlId="formUsername">
              <Form.Label>Username:</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  autoComplete={"username"}
                  placeholder="example-user-42"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  isInvalid={errors.username && touched.username}
                  isValid={!errors.username && touched.username}
                />
                <Form.Control.Feedback type="invalid" style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>
                  {errors.username}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formPassword">
              <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  autoComplete={"current-password"}
                  placeholder="********"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  isInvalid={errors.password && touched.password}
                  isValid={!errors.password && touched.password}
                />
                <Form.Control.Feedback type="invalid" style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>
                  {errors.password}
                </Form.Control.Feedback>
              </Form.Group>
              <Alert show={showError} variant="danger">
                  <Alert.Heading>Error Signing In</Alert.Heading>
                  <p>{errorMessage}</p>
                  <hr/>
                  <div className="d-flex justify-content-end">
                    <Button onClick={() => setShowError(false)} variant="outline-danger">
                      Dismiss
                    </Button>
                  </div>
              </Alert>
              <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
              Sign In
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!isSubmitting}
                style={{marginLeft: 5}}
              />
              <span className="sr-only">Loading...</span>
            </Button>
            </Form>
          )}
          </Formik>
      </div>         
    );
  } else {
    return(
    <div>      
        <h2>Please choose your password:</h2> 
        <Formik
          key={'choosePassword'}
          initialValues={{newPassword:"", newPasswordConfirm:""}}
          validationSchema={validationSchemaChoosePassword}
          onSubmit={ async (values, {setSubmitting, resetForm}) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);
              setShowError(false);
              await Auth.signIn(username, tempPassword)
                .then(user => {
                    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                        Auth.completeNewPassword(
                            user,               // the Cognito User Object
                            values.newPassword,       // the new password
                            // OPTIONAL, the required attributes
                            // {
                            //   email: 'xxxx@example.com',
                            //   phone_number: '1234567890'
                            // }
                        ).then(user => {
                            // at this time the user is logged in if no MFA required
                            // console.log(user);
                        }).catch(error => {
                            console.log('error signing in', error);
                            setShowError(true);
                            setSubmitting(false);
                        });
                    } else {
                        // other situations
                        console.log("Other situations")
                    }
                }).catch(error => {
                    console.log(error);
                    console.log('error signing in', error);
                    setShowError(true);
                    setSubmitting(false);
              });
          }}
        >
          {/* Callback function containing Formik state and helpers that handle common form actions */}
        {( {values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <Row>
              <Col>
                <Form.Group controlId="formNewPassword">
                <Form.Label>Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  autoComplete="new-password"
                  placeholder="********"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                  isInvalid={errors.newPassword && touched.newPassword}
                  isValid={!errors.newPassword && touched.newPassword}
                />
                <Form.Control.Feedback type="invalid" style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }}>
                  {errors.newPassword}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">Password meets requirements.</Form.Control.Feedback>
              </Form.Group>
              </Col>
              <Col>
              <Form.Group controlId="formNewPasswordConfirm">
              <Form.Label>Confirm:</Form.Label>
                <Form.Control
                  type="password"
                  name="newPasswordConfirm"
                  placeholder="********"
                  disabled={isSubmitting}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPasswordConfirm}
                  isInvalid={errors.newPasswordConfirm && touched.newPasswordConfirm}
                  isValid={!errors.newPasswordConfirm && touched.newPasswordConfirm}
                />
                <Form.Control.Feedback style={{
                        color: '#dc3545',
                        fontSize: '.8em',
                      }} type="invalid">
                  {errors.newPasswordConfirm}
                </Form.Control.Feedback>
                <Form.Control.Feedback type="valid">Passwords match.</Form.Control.Feedback>
              </Form.Group>
              </Col>
            </Row>
            <Alert show={showError} variant="danger">
                <Alert.Heading>Error Signing In</Alert.Heading>
                <p>{errorMessage}</p>
                <hr/>
                <div className="d-flex justify-content-end">
                  <Button onClick={() => setShowError(false)} variant="outline-danger">
                    Dismiss
                  </Button>
                </div>
            </Alert>
            <Button className='pwt-button' block size="lg" type="submit" disabled={isSubmitting}>
              Sign In
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                hidden={!isSubmitting}
                style={{marginLeft: 5}}
              />
              <span className="sr-only">Loading...</span>
            </Button>
          </Form>
        )}
        </Formik>
    </div>         
  );
  }
  
}

const SignInForm = compose(
  withRouter,
)(SignInFormBase);


export { SignInForm };

export { SignInPageBase };

export default SignInPage;