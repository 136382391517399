import React from 'react';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

export default function ToSPage(props) {    
    return(
        <Container style={{padding:0}}>
            <Helmet>
                <title>Terms of Service | PWT</title>
                <meta name="description" content="View the Personal Warning Temperature Terms of Service (ToS)." />
            </Helmet>
            <ToS></ToS>
        </Container>               
    );
}


function ToS(props) {
  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
        <h1>Personal Warning Temperature (PWT)</h1>
        <h2>Terms of Service (ToS)</h2>
        <div style={{backgroundColor: 'white', padding: 20, borderRadius: '25px'}}>
            <h3>Summary</h3>
            <div style={{overflowY:'scroll', height:'300px'}}>
            <div style={{fontSize:'80%', textAlign: 'left', fontStyle: 'italic', padding: '0.3em'}}>
            Disclaimer: This summary is not a part of the Terms of Use and is not a legal document. It is simply a handy reference for understanding the full terms. Think of it as the user-friendly interface to the legal language of our Terms of Use.
            </div>
            <p>
                <b>At Warning Temperature, LLC, part of our mission is to</b>
                :
            </p>
            <ul>
                <li>
                    <b>Empower people to understand their temperature and other symptoms to know when they may be sick </b>
                    in order to help control the spread of infectious diseases like COVID-19.
                </li>
                <li>
                    <b>Share your health data with your organization </b>
                    with your organization to help maintain a safer work environment.
                </li>
                <li>
                    <b>Share an anonymous version of your health data with medical researchers</b>
                    , with your permission, to help researchers get new data to help us control a pandemic like COVID-19.
                </li>
            </ul>
            <p>
                <b>You are free to</b>
                :
            </p>
            <ul>
                <li>
                    <b>Enter temperature and related health data </b>
                    for analysis, graphics, and an updated estimate of your normal temperature range as well as a warning temperature.
                </li>
                <li>
                    <b>Share your own data </b>
                    with anyone you choose including your doctor or health care provider.
                </li>
                <li>
                    <b>Ask us to share your health data with medical researchers </b>
                    and specify whether you would like us to anonymize your data before sharing.
                </li>
            </ul>
            <p>
                <b>Under the following conditions</b>
                :
            </p>
            <ul>
                <li>
                    <b>Responsibility </b>
                    &ndash; you take responsibility for the data you enter.
                </li>
                <li>
                    <b>Civility </b>
                    &ndash; you support a civil environment and do not harass other users.
                </li>
                <li>
                    <b>Lawful Behavior </b>
                    &ndash; you do not violate laws.
                </li>
                <li>
                    <b>No Harm </b>
                    &ndash; you do not threaten or harm our technology infrastructure.
                </li>
                <li>
                    <b>Terms of Service </b>
                    &ndash; you adhere to the below Terms of Service.
                </li>
            </ul>
            <p>
                <b>With the understanding that</b>
                :
            </p>
            <ul>
                <li>
                    <b>You are contributing your health data freely and permitting us to share your health </b>
                    &ndash; we protect your privacy to a reasonable extent, but ultimately, if you do become sick, our common intent is to share your health information to prevent the spread of infectious disease. We also share your health status and data with your organization to help provide a safe working environment.
                </li>
                <li>
                    <b>No Professional Advice </b>
                    &ndash; the advice and information you receive from this site is for informational purposes only and does not constitute professional advice, medical or otherwise, cannot diagnose or treat COVID-19, is not a substitute for other preventative measures such as social distancing and wearing a mask, and is provided with NO WARRANTY.
                </li>
            </ul>
            
            </div>
                        
            </div>
            <h3 style={{paddingTop:20}}>Full Terms</h3>
            <div style={{overflowY:'scroll', height:'500px'}}>
            <p>The mission of Personal Warning Temperature is to help people understand and share their temperature and other health information to better understand when a person might be getting sick and act accordingly. Currently, the common definition of fever being 100.4F or above is outdated and not ideal for preventing the spread of COVID-19 which tends to have mild symptoms in many people. Although motivated by the COVID-19 pandemic, Personal Warning Temperature is applicable to many other infectious diseases as well. Imagine a world in which everyone can test themselves daily to know whether to take special precautions. No one want to spread infection to their family, friends, or co-workers. We think Personal Warning Temperature can help, and we want to improve it until it works for everyone. That's our commitment at Warning Temperature, LLC.</p>
            <h4>
                Overview
            </h4>
            <p>These Terms of Service tell you about our Personal Warning Temperature service, our relationship to you as a user, and the rights and responsibilities that guide us both. We want you to know that Personal Warning Temperature enables you to upload health information and receive basic analysis from our algorithm. Often, we provide Personal Warning Temperature through your organization, and if so, by using Personal Warning Temperature, you permit us to share your health information with your organization in an effort to help ensure a safer workplace. This kind of health information from mostly healthy individuals is not available anywhere else that we know of, and, if you should become sick, it represents a valuable resource for medical researchers with whom you may choose to share your data provided your organization also opts in. We may also use your anonymized health information to promote the use of Personal Warning Temperature by other organizations. However, organizations and their individuals retain ownership of your data, and we make every reasonable effort to protect your privacy. We hope you agree with our mission, methods, and services, but if you have a problem, we want to know about it and work toward a solution. Personal Warning Temperature gets better when people like you make suggestions for improvement. Daily health monitoring with Personal Warning Temperature represents a new, but simple tool in the fight against pandemics like COVID-19. By using it every day and by allowing us to share your heath data in the ways we've identified (or, as required by law), you are contributing to the fight as well as potentially keeping yourself and others safer, just as they are doing the same for you. It is in everyone's interest to ensure the most accurate information is in Personal Warning Temperature, and we know you'll do your best to help.</p>
            <p>If you are a manager in an organization, you may have access to Personal Warning Temperature data for individuals in your organization. We know you will treat all health information confidentially. Your organization should have a privacy policy that covers sensitive health information. Warning Temperature, LLC has a privacy policy for Personal Warning Temperature, but it does not extend to your organization.</p>
            <h4>1. Our Services</h4>
            <p>Personal Warning Temperature provides a web application that enables upload of a user's current body temperature and other health information such as symptoms or measurements from devices other than a thermometer. Personal Warning Temperature algorithms use uploaded health information to provide a basic assessment which is based on uploaded data, mathematics, and publicly available guidance US Government agencies. This basic assessment is provided for informational purposes only, should not be considered medical advice, and is provided with NO WARRANTY.</p>
            <p>Personal Warning Temperature's assessment and recommendations do not constitute medical advice and should not be used to diagnose or treat medical conditions. An elevated temperature is one way to identify a person who may have a COVID-19 infection, although an infected person may be contagious without an elevated temperature or other easily detectable symptoms. Since an elevated temperature does not conclusively indicate a COVID-19 infection, further evaluation and diagnostic testing may be needed to determine if someone has a COVID-19 infection. Screening and health checks are not a replacement for other protective measures such as social distancing and wearing a mask.</p>
            <p>You are responsible for the data you upload. Personal Warning Temperature works best when you provide accurate health information. If you provide inaccurate information, Personal Warning Temperature may not provide a good assessment. We all understand that everyone makes mistakes, and normally a few inaccurate data points don't cause a problem, but the more accurate your data, the better for us all.</p>
            <h4>2. Privacy Policy</h4>
            <p>
                We ask that you review the terms of our <a href='/privacy-policy' target='_blank' rel="noopener noreferrer">Privacy Policy</a>
                , so that you are aware of how we collect and use your information. Because our services are used by people all over the world, personal information that we collect may be stored and processed in the United States of America or any other country in which we or our agents maintain facilities. By using our services, you consent to any such transfer of information outside your country.
            </p>
            <h4>3. Content</h4>
            <p></p>
            <ol>
                <li>You may find some part of Personal Warning Temperature information to be objectionable, erroneous, or inconvenient. If so, we want to work with you to improve Personal Warning Temperature. We ask that you use common sense and proper judgment when using our services.</li>
                <li>
                    <b>Our content is for general informational purposes only. No content should be considered professional advice or medical advice.</b>
                </li>
            </ol>
            <h4>4. Refraining from Certain Activities</h4>
            <p>We happily welcome your use of Personal Warning Temperature. It is very unlikely that you would engage in any problematic behavior. However, certain activities, whether legal or illegal, may be harmful to other users and violate our rules, and some activities may also subject you to liability. Therefore, for your own protection and for that of other users, you may not engage in such activities on our sites. These activities include:
            </p>
            <dl>
                <dd>
                    <b>Harassing and Abusing Others</b>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <ul>
                                <li>Engaging in harassment, threats, stalking, spamming, or vandalism; and</li>
                                <li>Transmitting chain mail, junk mail, or spam to other users.</li>
                            </ul>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <dl>
                <dd>
                    <b>Violating the Privacy of Others</b>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <ul>
                                <li>Infringing the privacy rights of others under the laws of the United States of America or other applicable laws (which may include the laws where you live or where you view or edit content);</li>
                                <li>Soliciting personally identifiable information for purposes of harassment, exploitation, violation of privacy, or any promotional or commercial purpose not explicitly approved by the Wikimedia Foundation; and</li>
                                <li>Soliciting personally identifiable information from anyone under the age of 18 for an illegal purpose or violating any applicable law regarding the health or well-being of minors.</li>
                            </ul>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <dl>
                <dd>
                    <b>Engaging in False Statements, Impersonation, or Fraud</b>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <ul>
                                <li>Intentionally or knowingly posting content that constitutes libel or defamation;</li>
                                <li>With the intent to deceive, posting content that is false or inaccurate;</li>
                                <li>Attempting to impersonate another user or individual, misrepresenting your affiliation with any individual or entity, or using the username of another user with the intent to deceive; and</li>
                                <li>Engaging in fraud.</li>
                            </ul>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <dl>
                <dd>
                    <b>Committing Infringement</b>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <ul>
                                <li>Infringing copyrights, trademarks, patents, or other proprietary rights under applicable law.</li>
                            </ul>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <dl>
                <dd>
                    <b>Misusing Our Services for Other Illegal Purposes</b>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <ul>
                                <li>Posting child pornography or any other content that violates applicable law concerning child pornography;</li>
                                <li>Posting or trafficking in obscene material that is unlawful under applicable law; and</li>
                                <li>Using the services in a manner that is inconsistent with applicable law.</li>
                            </ul>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <dl>
                <dd>
                    <b>Engaging in Disruptive and Illegal Misuse of Facilities</b>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <ul>
                                <li>Posting or distributing content that contains any viruses, malware, worms, Trojan horses, malicious code, or other device that could harm our technical infrastructure or system or that of our users;</li>
                                <li>Engaging in automated uses of the site that are abusive or disruptive of the services and have not been approved by the Wikimedia community;</li>
                                <li>Disrupting the services by placing an undue burden on a Project website or the networks or servers connected with a Project website;</li>
                                <li>Disrupting the services by inundating any of the Project websites with communications or other traffic that suggests no serious intent to use the Project website for its stated purpose;</li>
                                <li>Knowingly accessing, tampering with, or using any of our non-public areas in our computer systems without authorization; and</li>
                                <li>Probing, scanning, or testing the vulnerability of any of our technical systems or networks unless all the following conditions are met:</li>
                            </ul>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <dl>
                <dd>
                    <dl>
                        <dd>
                            <dl>
                                <dd>
                                    <ul>
                                        <li>such actions do not unduly abuse or disrupt our technical systems or networks;</li>
                                        <li>such actions are not for personal gain (except for credit for your work);</li>
                                        <li>you report any vulnerabilities to MediaWiki developers (or fix it yourself); and</li>
                                        <li>you do not undertake such actions with malicious or destructive intent.</li>
                                    </ul>
                                </dd>
                            </dl>
                        </dd>
                    </dl>
                </dd>
            </dl>
            <p>
                <span id="paid-contrib-disclosure"></span>
            </p>
            <p>We reserve the right to exercise our enforcement discretion with respect to the above terms.</p>
            <h4>5. Personal Identification Number (PIN) and Password Security</h4>
            <p>You are responsible for safeguarding your own PIN or password and should never disclose it to any third party.</p>
            <h4>6. Permissible Uses of Health Data Uploaded</h4>
            <p>By using Personal Warning Temperature, you are providing your permission for Warning Temperature, LLC to use it for certain technical purposes such as improving algorithms and system software, making backups, and moving it, on your behalf, from one organization to another. You are also providing permission for us to share it with managers in your organizations. If you and your organization opt-in, you are providing permission for us to share your health data with medical researchers. And, finally, you are providing permission for us to use an anonymous version of your data for the purpose of promoting use of Personal Warning Temperature by other organizations. All of these purposes are relevant to our mission of reducing the spread of infectious disease.</p>
            <h4>7. Data Retention and Termination</h4>
            <p>While the Personal Warning Temperature services is offered, we will retain your health data indefinitely unless you inform us that you or your organization would like us to delete your data. If the Personal Warning Temperature service will no longer be offered or , then your data will be deleted. Although we hope to continue service for a long time and that you will continue to contribute your health data to Personal Warning Temperature, you can stop using our services any time. In certain (highly unlikely) circumstances it may be necessary for us to terminate part or all of our services, terminate these Terms of Service, or block your account or access. If your account or access is blocked or otherwise terminated for any reason, your health data will be deleted. We reserve the right to suspend or end the services at any time, with or without cause, and with or without notice. Even after your use and participation are banned, blocked or otherwise suspended or the service is no longer offered, these Terms of Service will remain in effect with respect to relevant provisions.</p>
            <h4>8. Disputes and Jurisdiction</h4>
            <p>We hope that no serious disagreements arise involving you, but in the event there is a dispute, we encourage you to seek resolution directly with us at Warning Temperature, LLC. If you seek to file a legal claim against us, you agree to file and resolve it exclusively in a state or federal court located in Arlington County, Virginia. You also agree that the laws of the State of Virginia and, to the extent applicable, the laws of the United States of America will govern these Terms of Service, as well as any legal claim that might arise between you and us (without reference to conflict of laws principles). You agree to submit to the personal jurisdiction of, and agree that venue is proper in, the courts located in Arlington County, Virginia, in any legal action or proceeding relating to us or these Terms of Service.</p>
            <p>To ensure that disputes are dealt with soon after they arise, you agree that regardless of any statute or law to the contrary, any claim or cause of action you might have arising out of or related to use of our services or these Terms of Service must be filed within the applicable statute of limitations or, if earlier, one (1) year after the pertinent facts underlying such claim or cause of action could have been discovered with reasonable diligence (or be forever barred).</p>
            <h4>9. Disclaimers</h4>
            <p>At Warning Temperature, LLC, we do our best to provide Personal Warning Temperature to a very wide audience, but your use of our services is at your sole risk. We provide these services on an "as is" and "as available" basis, and we expressly disclaim all express or implied warranties of all kinds, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We make no warranty that our services will meet your requirements, be safe, secure, uninterrupted, timely, accurate, or error-free, or that your information will be secure.</p>
            <p>We are not responsible for the content based on unforseeable software bugs or technical conditions out of our control, and you release us, our directors, officers, employees, and agents from any claims and damages, known and unknown, arising out of or in any way connected with any claim you have against any such third parties. No advice or information, whether oral or written, obtained by you from us or through or from our services creates any warranty not expressly stated in these Terms of Service.</p>
            <p>Any material downloaded or otherwise obtained through your use of our services is done at your own discretion and risk, and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material. You agree that we have no responsibility or liability for the deletion of, or the failure to store or to transmit, any content or communication maintained by the service. We retain the right to create limits on use and storage at our sole discretion at any time with or without notice.</p>
            <p>Some states or jurisdictions do not allow the types of disclaimers in this section, so they may not apply to you either in part or in full depending on the law.</p>
            <h4>10. Limitation on Liability</h4>
            <p>Warning Temperature, LLC. will not be liable to you or to any other party for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, regardless of whether we were advised of the possibility of such damage. In no event shall our liability exceed one hundred U.S. dollars (USD 100.00) in aggregate. In the case that applicable law may not allow the limitation or exclusion of liability or incidental or consequential damages, the above limitation or exclusion may not apply to you, although our liability will be limited to the fullest extent permitted by applicable law.</p>
            <h4>11. Modifications to these Terms of Service</h4>
            <p>Because it may be necessary to modify these Terms of Service from time to time, we will post a link to Terms of Service on your Personal Warning Temperature home page. We ask that you please periodically review the most up-to-date version of these Terms of Service. Your continued use of our services constitutes an acceptance of these Terms of Service on your part. For the protection of Warning Temperature, LLC. and other users like yourself, if you do not agree with our Terms of Service, you cannot use our services.</p>
            <h4>12. Other Terms</h4>
            <p>These Terms of Service do not create an employment, agency, partnership, or joint venture relationship between you and us, Warning Temperature, LLC. If you have not signed a separate agreement with us, these Terms of Service are the entire agreement between you and us. If there is any conflict between these Terms of Service and a signed written agreement between you and us, the signed agreement will control.</p>
            <p>You agree that we may provide you with notices by email or postings on your Personal Warning Temperature website.</p>
            <p>If in any circumstance, we do not apply or enforce any provision of these Terms of Service, it is not a waiver of that provision.</p>
            <p>You understand that, unless otherwise agreed to in writing by us, you have no expectation of compensation for any activity, contribution, or idea that you provide to us.</p>
            <p>Notwithstanding any provision to the contrary in these Terms of Service, we (Warning Temperature, LLC.) and you agree not to modify the applicable terms and requirements of any health data permissions authorized by these Terms of Service.</p>
            <p>These Terms of Service were written in English (U.S.). While we hope that translations of these Terms of Service are accurate, in the event of any differences in meaning between the original English version and a translation, the original English version takes precedence.</p>
            <p>If any provision or part of a provision of these Terms of Service is found unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Service and will be enforced to the maximum extent permissible, and all other provisions of these Terms of Service will remain in full force and effect.</p>
            <h4>
                Thank You!
            </h4>
            <p>We appreciate your taking the time to read these Terms of Service, and we are very happy to have you contributing health data to Personal Warning Temperature and using our services. Through your contributions, you are helping to fight the COVID-19 pandemic and potentially protect all of us from the spread of disease. This is a really big deal &ndash; without this information, people may not know when they are sick and might infect others. By sharing your health information with others in your organization, we are all better off. Please help us improve Personal Warning Temperature if you have ideas. Please help us spread the word. Encourage your family, friends, associates, and neighbors to monitor their health every day with Personal Warning Temperature.</p>
            <p>
                <b>These Terms of Service went into effect on July 15, 2020.</b>
            </p>
            <p>
                <b>These Terms of Service were modified August 5, 2020 to further emphasize that Personal Warning Temperature analysis does not constitute medical advice, cannot diagnose or treat COVID-19, and is not a substitute for other preventative measures such as social distancing and wearing a mask.</b>
            </p>
            <p style={{fontSize:'80%', textAlign: 'left', fontStyle: 'italic', padding: '0.3em'}}>
                Text is available under the <a href="https://creativecommons.org/licenses/by-sa/3.0/" target='_blank' rel="noopener noreferrer">Creative Commons Attribution-ShareAlike License</a>
                .
            </p>
        </div>
    </div>   
  );
  
}


