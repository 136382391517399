import React, {useContext, useEffect, useState} from 'react';
import { compose } from 'recompose';

import { Container } from 'react-bootstrap';

import UpdateTemperatureModule from '../../components/UpdateTemperature'

import { Helmet } from 'react-helmet';

import { Auth } from 'aws-amplify'

import { AuthContext } from '../../components/AuthContext'


function HomePage(props) {
  return (
    <Container style={{padding:0}}>
      <Helmet>
          <title>Home | PWT</title>
          <meta name="description" content="Update your temperature." />
      </Helmet>
      <HomePageBase></HomePageBase>
    </Container>           
    );  
}

function HomePageBase(props) {
  const user = useContext(AuthContext)

  return (
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <GreetingModule></GreetingModule>
      <hr/>
      <h1>Update Your Temperature</h1>
      <p>You should update your temperature at least once each morning to help build an accurate temperature profile. Twice a day is optimal (morning and evening).</p>
      <UpdateTemperatureModule></UpdateTemperatureModule>
    </div>
  );
}

function GreetingModule(props){
  const userContext = useContext(AuthContext);
  const [greetingMessage, setGreetingMessage] = useState('');
  const [loading, setLoading] = useState(true);

  function getGreeting() {
    const HOUR_MORNING_MINIMUM = 4;
    const HOUR_MORNING_MAXIMUM = 12;
    const HOUR_AFTERNOON_MINIMUM = 12;
    const HOUR_AFTERNOON_MAXIMUM = 18;
    const HOUR_EVENING_MINIMUM = 18;
    const HOUR_EVENING_MAXIMUM = 24;
    const HOUR_NIGHT_MINIMUM = 0;
    const HOUR_NIGHT_MAXIMUM = 4;

    let currentDate: Date = new Date();
    let hour = currentDate.getHours();
    let greeting = '';
    if ((hour >= HOUR_MORNING_MINIMUM) && (hour < HOUR_MORNING_MAXIMUM)) {
      greeting = 'Good morning';
    } else if ((hour >= HOUR_AFTERNOON_MINIMUM) && (hour < HOUR_AFTERNOON_MAXIMUM)) {
      greeting = 'Good afternoon';
    } else if (((hour >= HOUR_EVENING_MINIMUM) && (hour < HOUR_EVENING_MAXIMUM)) || ((hour >= HOUR_NIGHT_MINIMUM) && (hour < HOUR_NIGHT_MAXIMUM))) {
      greeting = 'Good evening';
    } else {
      greeting = 'Welcome';
    }
    return greeting;
  }

  

  useEffect(() => {
    async function updateGreetingMessage() {
      setLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      var username = user?.username;
      var fullGreeting = '';
      if (username != null) {
        fullGreeting = `${getGreeting()}, ${username}.`;
        setGreetingMessage(fullGreeting);
      } else {
        fullGreeting = `${getGreeting()}.`;
        setGreetingMessage(fullGreeting);
      }
      setLoading(false);
    }

    updateGreetingMessage();
  }, []);

  function getGreeting() {
    const HOUR_MORNING_MINIMUM = 4;
    const HOUR_MORNING_MAXIMUM = 12;
    const HOUR_AFTERNOON_MINIMUM = 12;
    const HOUR_AFTERNOON_MAXIMUM = 18;
    const HOUR_EVENING_MINIMUM = 18;
    const HOUR_EVENING_MAXIMUM = 24;
    const HOUR_NIGHT_MINIMUM = 0;
    const HOUR_NIGHT_MAXIMUM = 4;

    let currentDate: Date = new Date();
    let hour = currentDate.getHours();
    let greeting = '';
    if ((hour >= HOUR_MORNING_MINIMUM) && (hour < HOUR_MORNING_MAXIMUM)) {
      greeting = 'Good morning';
    } else if ((hour >= HOUR_AFTERNOON_MINIMUM) && (hour < HOUR_AFTERNOON_MAXIMUM)) {
      greeting = 'Good afternoon';
    } else if (((hour >= HOUR_EVENING_MINIMUM) && (hour < HOUR_EVENING_MAXIMUM)) || ((hour >= HOUR_NIGHT_MINIMUM) && (hour < HOUR_NIGHT_MAXIMUM))) {
      greeting = 'Good evening';
    } else {
      greeting = 'Welcome';
    }
    return greeting;
  }

  if (greetingMessage == '') { 
    return(
      <div style={{textAlign:'center'}}>
        <h2 style={{overflowWrap:'anywhere', wordWrap:'break-word'}}>{``}</h2>
      </div>
    );
  } else {
    return(
      <div style={{textAlign:'center'}}>
        <h2 style={{overflowWrap:'anywhere', wordWrap:'break-word'}}>{`${greetingMessage}`}</h2>
      </div>
    );
  }
  
}

export default HomePage;