import React, { useState } from 'react';

import { Auth } from 'aws-amplify'

import { Alert, Button, Container, Form } from "react-bootstrap";

import LoadingOverlay from 'react-loading-overlay';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Helmet } from 'react-helmet';

function AccountPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
          <title>Account | PWT</title>
          <meta name="description" content="Update your temperature." />
        </Helmet>
        <AccountPageBase></AccountPageBase>
    </Container>         
  );
}

function AccountPageBase(props) {  
  return(
    <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>
      <h1>Account</h1>
      <ChangePasswordModule></ChangePasswordModule>
    </div>
  );    
}


function ChangePasswordModule(props) {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");


  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
    .min(8,"Password must be at least 8 characters.")
    .max(99, "Password must be at most 99 characters.")   
    .required("Your password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`])\S{8,99}$/,
      "Passwords must contain an uppercase letter, lowercase letter, number, and special character."
    ),
    newPassword: Yup.string()
    .min(8,"Password must be at least 8 characters.")
    .max(99, "Password must be at most 99 characters.")   
    .required("You must choose a password.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/,><':;|_~`])\S{8,99}$/,
      "Passwords must contain an uppercase letter, lowercase letter, number, and special character."
    ),
    newPasswordConfirm: Yup.string()
    .required("Please confirm the password.")
    .label('Confirm password')
    .test('passwords-match', 'Passwords must match.', function(value) {
      return this.parent.newPassword === value;
    }),
  });




  return(
    <div>       
      <Formik
        initialValues={{ username: "", currentPassword:"", newPassword:"", newPasswordConfirm:""}}
        validationSchema={changePasswordSchema}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          setSubmitting(true);
          setShowError(false);
          setShowSuccess(false);
          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
              currentUser,
              values.currentPassword,
              values.newPassword
            );
            setSubmitting(false);
            setShowSuccess(true);
      
          } catch (error) {
            let code = error.code;
            let message = ""
            switch (code) {
              case "NotAuthorizedException":
                message = "Incorrect password. Double check your current password and try again."
                break;
              case "LimitExceededException":
                message = "Attempt limit exceeded, please try after some time.";
                break;
              case "InvalidPasswordException":
                message = "Password does not meet requirements. Passwords must be between 8 and 99 characters long and contain an uppercase letter, lowercase letter, number, and special character.";
                break;
              case "UserNotFoundException":
                message = "An account by the specified username does not exist.";
                break;
              default:
                message = "Unable to sign in at this time. Please contact support for assistance."
                break;
            }
            console.log('error signing in', error);
            setErrorMessage(message);
            setShowError(true);
          }

        }}
      >
      {( {values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="mx-auto">
          <LoadingOverlay
          active={isSubmitting}
          spinner
          text='Updating password...'
          styles={{
            overlay: (base) => ({
              ...base,
            })
          }}
          >
          <h2>Change Your Password</h2>
          <Form.Group controlId="formNewUsername" hidden={true}>
            <Form.Label>Username:</Form.Label>
              <Form.Control
                type="text"
                name="username"
                autoComplete={"username"}
                placeholder="example-user-42"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                isInvalid={errors.username && touched.username}
                isValid={!errors.username && touched.username}
              />
              <Form.Control.Feedback type="invalid" style={{
                      color: '#dc3545',
                      fontSize: '.8em',
                    }}>
                {errors.username}
              </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formNewUsernameConfirm">
          <Form.Label>Current Password:</Form.Label>
            <Form.Control
              type="password"
              name="currentPassword"
              autoComplete={"current-password"}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.currentPassword}
              isInvalid={errors.currentPassword && touched.currentPassword}
              isValid={!errors.currentPassword && touched.currentPassword}
            />
            <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
              {errors.currentPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formCurrentPassword">
            <Form.Label>New Password:</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              autoComplete={"new-password"}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
              isInvalid={errors.newPassword && touched.newPassword}
              isValid={!errors.newPassword && touched.newPassword}
            />
            <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
              {errors.newPassword}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formNewPasswordConfirm">
          <Form.Label>Confirm New Password:</Form.Label>
            <Form.Control
              type="password"
              name="newPasswordConfirm"
              autoComplete={"new-password"}
              placeholder="********"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPasswordConfirm}
              isInvalid={errors.newPasswordConfirm && touched.newPasswordConfirm}
              isValid={!errors.newPasswordConfirm && touched.newPasswordConfirm}
            />
            <Form.Control.Feedback type="invalid" style={{
                    color: '#dc3545',
                    fontSize: '.8em',
                  }}>
              {errors.newPasswordConfirm}
            </Form.Control.Feedback>
          </Form.Group>
          <Alert show={showSuccess} variant="success">
            <Alert.Heading>Password Updated</Alert.Heading>
            <p>{'Your password has successfully been changed.'}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowSuccess(false)} variant="outline-success">
                Dismiss
              </Button>
            </div>
          </Alert>
          <Alert show={showError} variant="danger">
            <Alert.Heading>Failed to Update Password</Alert.Heading>
            <p>{errorMessage}</p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowError(false)} variant="outline-danger">
                Dismiss
              </Button>
            </div>
          </Alert>
          <Button className='pwt-button' disabled={isSubmitting} block bssize="large"  type="submit">
            Update Password
          </Button>
          </LoadingOverlay>
        </Form>
        )}
        </Formik>
    </div>
  );    
}

export default (AccountPage);
