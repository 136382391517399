import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';

import { Button, Container, Modal, Row, Col } from "react-bootstrap";

import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

import { Helmet } from 'react-helmet';
import { API, Auth } from 'aws-amplify'


function HistoryPage(props) {
  return(
    <Container style={{padding:0}}>
      <Helmet>
        <title>History | PWT</title>
        <meta name="description" content="View your temperature history."/>
      </Helmet>   
        <HistoryPageBase></HistoryPageBase>
    </Container>         
  );
}


function HistoryPageBase(props) {
  
    return(
      <div style={{backgroundColor:"whitesmoke", borderRadius:"25px", marginTop:15, marginBottom:15, padding:15}}>       
        <h1>Temperature History</h1>
        <p>Use this menu to filter and view your temperature history. You may also export your data to a spreadsheet.</p>
        <HistoryModule></HistoryModule>
      </div>   
  );
    
}


function HistoryModuleBase(props) {
  const [showBusy, setShowBusy] = useState(true);

  const [temperatureData, setTemperatureData] = useState([]);

  const [endDate, setEndDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));  // 30 days

  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const handleClose = () => setShowSignUpModal(false);
  const handleShow = () => setShowSignUpModal(true);

  const [selectedRows, setSelectedRows] = useState([]);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
    {
      (size === 0)
      ?
      ` No entries found.`
      :
      ` Showing ${ from } to ${ to } of ${ size } entries.`
    }
    </span>
  );
  
  const timestampFormatter = (cell, row) => {
      return (
        cell.formatted
      );
  }
  const symptomsFormatter = (cell, row) => {
    let symptomsList = cell.join(', ')
    return (
      symptomsList
    );
}

const isEntryInList = (entry, list) => {
  for (let i = 0; i < list.length; i++) {
      if (list[i].timestamp.seconds === entry.timestamp.seconds) {
          return i;
      } 
  }

  return -1;
}

const selectRow = {
  mode: 'checkbox',
  onSelect(row, isSelected, e) {
    if (isSelected) {
      // Add the row
      setSelectedRows([...selectedRows, row])
    } else {
      // Remove the row
      let clone = selectedRows;
      let deleteIndex = clone.findIndex(element => {return element.timestamp.seconds === row.timestamp.seconds});
      clone.splice(deleteIndex, 1)
      setSelectedRows([...clone]);
    }
   },
   onSelectAll: (isSelect, rows, e) => {
    let selectedClone = selectedRows;
    if (isSelect) {
      for (let index = 0; index < rows.length; index ++) {
        let element = rows[index];
        let location = isEntryInList(element, selectedClone)
        if (location === -1) {
          // setSelectedRows([...selectedRows, element])
          selectedClone.push(element);
        } else {
          // do nothing; the row is already in the list
        }
      };
      setSelectedRows([...selectedClone])
    } else {
        for (let index = 0; index < rows.length; index ++) {
          let element = rows[index];
          let location = isEntryInList(element, selectedClone)
          if (location === -1) {
            // do nothing; it doesn't exist
          } else {
            // let clone = selectedRows;
            selectedClone.splice(location, 1)
          }
      };
      setSelectedRows([...selectedClone])
    }
  }
  };

  const columns = [{
    dataField: 'id',
    text: '#',
    sort: true
  },
  {
    dataField: 'temperature',
    text: 'Temperature (Fahrenheit)',
    sort: true
  }, 

  {
    dataField: 'timestamp',
    text: 'Timestamp',
    sort: true,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return b.seconds - a.seconds;
      }
      return a.seconds - b.seconds; // desc
    },
    formatter: timestampFormatter,
    csvFormatter: timestampFormatter
  },
  {
    dataField: 'symptoms',
    text: 'Symptoms',
    sort: true,
    formatter: symptomsFormatter
  },
];

  const handleDelete = async () => {
    handleClose();
    let removeTimestamps = [];
    if (selectedRows.length > 0) {
      selectedRows.forEach(element => {
        removeTimestamps.push(element.timestamp.seconds)
      });
    } else {
      return;
    }
    setShowBusy(true);
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const requestData = {
        headers: {
          Authorization: token
        },
        body: {
          remove: true,
          timestamps: removeTimestamps,
        }
      }
      try {
        await API.post('temperaturetrackapi', '/update', requestData)
        let clone = temperatureData;
        removeTimestamps.forEach(timestamp => {
          let deleteIndex = clone.findIndex(element => {return element.timestamp.seconds === timestamp});
          clone.splice(deleteIndex, 1)
        });
        setSelectedRows([])
        setTemperatureData([...clone]);
      } catch (error) {
        console.log(error.response.data.message)
      }
      setShowBusy(false);
  };

  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    let buttonClass = 'pwt-button';
    if (active) {
      buttonClass = 'pwt-button-secondary'
    }
    return (
      <div key = { page } style={{ paddingLeft:1, paddingRight:1 }}>
        <Button className={buttonClass} key={ page } onClick={ handleClick }>{ page }</Button>
      </div>
    );
  };

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
    size
  }) => (
    <div className="btn-group" role="group">
      {
        options.map((option) => {
          const active = currSizePerPage === `${option.page}`;
          let buttonClass = 'pwt-button';
          if (active) {
            buttonClass = 'pwt-button-secondary'
          }
          return (
            <div key={ option.text } style={{paddingLeft:1, paddingRight:1}}>
              <Button className={buttonClass} onClick={ () => onSizePerPageChange(option.page) } >{ option.text }</Button>
            </div>
          );
          
        })
      }
    </div>
  );

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport(temperatureData);
    };
    return (
      <div>
        <Button className='pwt-button' block size='sm' type='button' disabled={(temperatureData.length < 1)} onClick={ handleClick }>Export Spreadsheet</Button>
      </div>
    );
  };

  const options = {
    pageButtonRenderer,
    sizePerPageRenderer,
    paginationTotalRenderer: customTotal,
    custom: true,
    paginationSize: 3,
    pageStartIndex: 1,
    totalSize: temperatureData.length,
    showTotal: true,
    hideSizePerPage: (temperatureData.length > 1) ? false : true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '25', value: 25
    },{
      text: '50', value: 50
    }, {
      text: 'All', value: temperatureData.length
    }]
  };

  const historyTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="id"
        columns={ columns }
        data={ temperatureData }
        exportCSV = { {
          fileName: `PWT_History-(${new Date().toLocaleString(undefined, { timeZoneName: 'short' })}).csv`,
        } }
      >    
        {
          toolkitprops => (
            <div>
              <MyExportCSV { ...toolkitprops.csvProps }></MyExportCSV>
              <Button className='pwt-button' block size='sm' type='button' style={{marginTop: 15}} disabled={(selectedRows.length < 1)} onClick={ handleShow }>Remove {selectedRows.length} Selected Entries</Button>
              <hr/>
              <h2>Temperature History</h2>
              <LoadingOverlay
                active={showBusy}
                spinner
                // text='Loading history...'
                styles={{
                  overlay: (base) => ({
                    ...base,
                  })
                }}
              >
              <BootstrapTable
                bootstrap4
                wrapperClasses="table-responsive"
                striped
                hover
                condensed
                selectRow={ selectRow }
                { ...toolkitprops.baseProps }
                { ...paginationTableProps }
              />
              <Row>
                <Col>
                  <div style={{display: 'flex', flexDirection:'column'}}>
                    <SizePerPageDropdownStandalone { ...paginationProps } />
                    <PaginationTotalStandalone { ...paginationProps } />
                  </div>
                </Col>
                <Col sm={1}>
                  <div>
                    <PaginationListStandalone { ...paginationProps } />
                  </div>
                </Col>
              </Row>
              </LoadingOverlay>
            </div>
          )
        }
      </ToolkitProvider>
    </div>
  );

  useEffect(() => {
      async function getHistory() {
        setShowBusy(true);
        var localStartDate = startDate;
        var localEndDate = endDate;
        localStartDate.setHours(0,0,0,0);
        localEndDate.setHours(23,59,59,999);
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const requestData = {
          headers: {
            Authorization: token
          },
          body: {
            start_date: localStartDate.getTime()/1000,
            end_date: localEndDate.getTime()/1000,
          }
        }
        try {
          const data = await API.post('temperaturetrackapi', '/healthdata', requestData)
          var entries = [];
          for (let count = 0; count < data?.Items.length; count++) {
            let entry = data.Items[count];
            let seconds = entry.timestamp;
            entries.push(entry);
            entries[entries.length - 1].timestamp = { seconds: seconds, formatted: (new Date(seconds * 1000)).toLocaleString(undefined, { timeZoneName: 'short' })};
            entries[entries.length - 1].id = (data.Items.length - entries.length + 1);
          }
          setTemperatureData(entries);
        } catch (error) {
          console.log(error.response.data.message)
        }
        setShowBusy(false);
      }
      getHistory();
  }, [startDate, endDate]);


  const ConfirmDeletePrompt = () => {
    const listItems = selectedRows.map((row) => <li key={row.timestamp.seconds}>{row.temperature}, {row.timestamp.formatted}</li>);
    return (
      <Modal
          show={showSignUpModal}
          onHide={handleClose}
          keyboard={false}
          centered
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>The following entries have been selected: </p>
            <div>
              <ol>
                {listItems}
              </ol>
            </div>
            { (listItems.length < 2) ?
            <p>Are you sure you want to remove this entry? Removed entries cannot be recovered.</p>
            :
            <p>Are you sure you want to remove these entries? Removed entries cannot be recovered.</p>
            }
        </Modal.Body>
        <Modal.Footer>
          <Button className={'pwt-button-secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button className={'pwt-button'} onClick={handleDelete}>
          { (listItems.length < 2) ?
            'Remove Entry'
            :
            'Remove Entries'
            }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }


    return(
      <div>       
        <h2>Filter History:</h2>       
        <Row>
          <Col style={{backgroundColor:'whitesmoke'}}>
              <div style={{padding:5}}>
                <label><b>From</b>:</label>
                  <div>
                  <DatePicker selected={startDate} onChange={date => setStartDate(date)} />
                  </div>
              </div>
              <div style={{padding:5}}>
                <label><b>To</b>:</label>
                <div>
                  <DatePicker selected={endDate} onChange={date => setEndDate(date)}/>
                </div>
              </div>
          </Col>
        </Row>
    <div style={{paddingTop:20}}>
      <PaginationProvider
            pagination={
              paginationFactory(options)
            }
          >
            { historyTable }
      </PaginationProvider>
    </div >
    <ConfirmDeletePrompt/>
  </div>   
  );
    
}




const HistoryModule = compose(
)(HistoryModuleBase);

export { HistoryModule };

export default (HistoryPage);
