import React, { useState } from 'react';
import { compose } from 'recompose';

import { withRouter } from "react-router";

import * as ROUTES from '../../constants/routes';

import { AuthContext } from '../../components/AuthContext'

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

import pwt_box_logo from '../../Resources/Images/PWT/PWT_box_full_logo.png'; // Tell webpack this JS file uses this image

import { Auth } from 'aws-amplify';

function Header(props) {
  return (
    <AuthContext.Consumer>
      {values =>
        values.user ? <HeaderAuth {...props}/> : <HeaderNonAuth {...props}/>
      }
    </AuthContext.Consumer>
  );
}
function HeaderNonAuth(props) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expand="lg" expanded={expanded} style={{backgroundColor:'whitesmoke'}}>
      <Navbar.Brand onClick={() => setTimeout(() => {props.history.push(ROUTES.HOME); setExpanded(false); }, 150)}>
        <img className="img-fluid" src={pwt_box_logo} alt='PWT Logo' style={{width:(100*1.75*0.80),height:100*0.80}}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav activeKey={props.history.location.pathname} variant='tabs'>
          <Nav.Link disabled={false} eventKey = {ROUTES.SIGN_IN} onClick={() => setTimeout(() => {props.history.push(ROUTES.SIGN_IN); setExpanded(false); }, 150)}>Sign In</Nav.Link>
          <NavDropdown title="More" id="basic-nav-dropdown">
            <NavDropdown.Item eventKey = {ROUTES.ABOUT_US} onClick={() => setTimeout(() => {props.history.push(ROUTES.ABOUT_US); setExpanded(false); }, 150)}>About Us</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}



function HeaderAuth(props) {
  const [expanded, setExpanded] = useState(false);

  async function signOut(event) {
    try {
      await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
  }
  
  return (
    <Navbar expand="lg" expanded={expanded} style={{backgroundColor:'whitesmoke'}}>
      <Navbar.Brand onClick={() => setTimeout(() => {props.history.push(ROUTES.HOME); setExpanded(false); }, 150)}>
        <img className="img-fluid" src={pwt_box_logo} alt='PWT Logo' style={{width:(100*1.75*0.80),height:100*0.80}}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav variant='tabs'>
          <Nav.Link eventKey = {ROUTES.HOME} onClick={() => setTimeout(() => {props.history.push(ROUTES.HOME); setExpanded(false); }, 150)}>Home</Nav.Link>
          <Nav.Link eventKey = {ROUTES.PROFILE} onClick={() => setTimeout(() => {props.history.push(ROUTES.PROFILE); setExpanded(false); }, 150)}>Temperature Profile</Nav.Link>
          <NavDropdown title="More" id="basic-nav-dropdown">
            <NavDropdown.Item eventKey = {ROUTES.ACCOUNT} onClick={() => setTimeout(() => {props.history.push(ROUTES.ACCOUNT); setExpanded(false); }, 150)} >Account</NavDropdown.Item>
            <NavDropdown.Item eventKey = {ROUTES.GROUPS} onClick={() => setTimeout(() => {props.history.push(ROUTES.GROUPS); setExpanded(false); }, 150)}>Groups</NavDropdown.Item>
            <NavDropdown.Item eventKey = {ROUTES.HISTORY} onClick={() => setTimeout(() => {props.history.push(ROUTES.HISTORY); setExpanded(false); }, 150)}>History</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item eventKey = {ROUTES.ABOUT_US} onClick={() => setTimeout(() => {props.history.push(ROUTES.ABOUT_US); setExpanded(false); }, 150)}>About Us</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={async () => setTimeout(() => {signOut(); setExpanded(false); }, 150)}>Sign Out</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );  
}

export default compose(
  withRouter,
)(Header)
